import React from 'react'
import Add from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';
import { DataGridPro, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import ActionBarButton from '../../components/ActionBarButton';
import CustomDivider from '../../components/CustomDivider';
import SideSheet from '../../components/SideSheet';
import DeleteEntity from '../../components/SideSheets/DeleteEntity';
import LocationCreate from './SideSheets/LocationCreate';
import LocationUpdate from './SideSheets/LocationUpdate';
import { fetchLocations } from '../../services/LocationService';
import { ListViewProps } from '../../react-app-env';
import { ActionBar, ActionButton } from 'prolonreactcomponents';
import GridLocalSearchField from '../../components/GridLocalSearchField';

export default function LocationListView({ setSideSheet }: ListViewProps) {
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            width: 175
        },
        {
            field: 'street',
            headerName: 'Street',
            type: 'string',
            width: 250
        },
        {
            field: 'postalCode',
            headerName: 'Postal',
            type: 'string',
            width: 75,
        },
        {
            field: 'city',
            headerName: 'City',
            type: 'string',
            width: 175,
        },
        {
            field: 'coord',
            headerName: 'Coordinates',
            type: 'string',
            width: 175,
            valueGetter: (params: GridValueGetterParams) => {
                return params.value.latitude + ", " + params.value.longitude
            }
        }
    ];
    const [errors, setErrors] = useState(
        {
            hasDeleteError: false,
            deleteError: null,
            hasFetchError: false,
            fetchError: null,
        }
    );

    const [Locations, setLocations] = useState<any[]>([])
    const [refresh, setRefresh] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<any[]>([])
    const [searchValue, setSearchValue] = useState<any[]>([])

    useEffect(() => {
        const getLocations = async () => {
            const Locationsfromserver = await fetchLocations()
            setLocations(Locationsfromserver)
        }
        getLocations()

    }, [refresh])

    const closeSideSheet = () => {
        setSideSheet(null);
    }
    const handleEditLocation = (entity: string) => {
        setSideSheet(<SideSheet><LocationUpdate closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh} entity={entity}></LocationUpdate> </SideSheet>)

    }
    const handleNewLocation = () => {
        setSideSheet(<SideSheet><LocationCreate closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh}></LocationCreate> </SideSheet>)
    }

    const handleDelete = () => {
        let selectedEntities = Locations.filter(x => selectionModel.includes(x.id));
        setSideSheet(<SideSheet><DeleteEntity selected={selectedEntities} closeSideSheet={closeSideSheet} setRefresh={setRefresh} type={"location"} refresh={refresh}></DeleteEntity> </SideSheet>)
    }
    const ToolBar = () => {
        return (
            <Box>
                <ActionBar paddingBottom={0} marginLeft={5}>
                    <ActionButton text={"Add New Location"} icon={<Add />} action={handleNewLocation} />
                    <ActionButton text={"Delete"} action={handleDelete} icon={<DeleteOutlineIcon />} disabled={!toggle} />
                    <GridLocalSearchField />
                </ActionBar>
            </Box>
        )
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <Box style={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                <Typography variant='h6' sx={{
                    pl: 2,
                    py: 1
                }} >
                    Locations
                </Typography>
                <DataGridPro
                    disableSelectionOnClick
                    rows={Locations}
                    columns={columns}
                    density='compact'
                    autoPageSize
                    pagination
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        ids.length > 0 ? setToggle(true) : setToggle(false);

                    }}
                    onRowClick={(params, event) => {
                        handleEditLocation(params.row.id)
                    }}
                    components={{
                        Toolbar: ToolBar
                    }}
                    rowHeight={30}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            // backgroundColor: "transparent",
                            "& .MuiDataGrid-cell": {
                                padding: 0
                            }
                        },
                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "whitesmoke"
                            },
                            "&:first-of-type": {
                                borderTop: "1px solid rgba(224, 224, 224, 1)"
                            }
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none"
                        }
                    }}
                />
            </Box>
        </Box>
    );
}