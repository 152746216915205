import * as React from 'react'
import { Alert, Box, Button, Input, List, ListItem, ListItemText, Typography, Fade } from '@mui/material';
import { useState } from 'react';
import { deleteChargePoint } from '../../services/ChargePointService';
import { deleteLoadGroup } from '../../services/LoadGroupService';
import { deleteLocation } from '../../services/LocationService';
import { deleteServiceProvider } from '../../services/ServiceProviderService';

type DeleteEntityProps = {
  selected: any,
  closeSideSheet: any,
  setRefresh: any,
  type: string,
  refresh: boolean
}

export default function DeleteEntity({ selected, closeSideSheet, setRefresh, type, refresh }: DeleteEntityProps) {
  const [errors, setErrors] = useState<{ nameError?: string; deleteError?: string; unauthorizedError?: string }>({});
  const [deleteText, setDeleteText] = useState<string>("");
  const confirmButtonDisabled = !(deleteText === "delete");

  const handleDelete = async (id: string) => {
    let resp;
    switch (type) {
      case "location":
        resp = await deleteLocation(id);
        break;
      case "chargepoint":
        resp = await deleteChargePoint(id);
        break;
      case "loadgroup":
        resp = await deleteLoadGroup(id);
        break;
      case "serviceprovider":
        resp = await deleteServiceProvider(id);
    }
    if (resp) {
      setRefresh(!refresh);
      closeSideSheet();
    } else {
      let e = { ...errors };
      e.deleteError = "There was a problem deleting this entity"
      setErrors(e);
    }
  }
  const handleCancel = () => {
    closeSideSheet();
  }

  return (
    <>
      <Box flex={1} overflow={'auto'} >
        <Typography
          sx={{
            mt: 2,
            color: "#030F4F",
            fontSize: 25
          }}
        >
          Delete Entities
        </Typography>
        <Typography component={'span'}
          sx={{
            mt: 1,
            color: "#030F4F",
          }}
        >
          You want to delete the following {selected?.length} entities from the database
        </Typography>
        <Typography variant='body2' component={'span'}
          sx={{
            color: "#030F4F",
          }}
        >
          <List dense>
            {selected?.map(function (entity: { id: string; name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }): JSX.Element {
              return (
                <ListItem key={"deleteItem - " + entity.id}>
                  <ListItemText
                    primary={entity.name} />
                </ListItem>
              );
            }
            )}
          </List>
        </Typography>
        <Box>
          <Alert severity="warning" sx={{ mr: 2 }}>
            Warning! Delete is permanent
          </Alert>
        </Box>
        <Typography variant="body2" >
          Confirm deletion
        </Typography>
        {errors.deleteError ? <Fade in={typeof errors.deleteError !== 'undefined'}><Alert severity="error">{errors.deleteError}</Alert></Fade> : <></>}
        <Box border={1} mr={3} style={{ borderColor: "#8a8886" }}>
          <Input
            value={deleteText}
            placeholder={"Enter 'delete' to confirm"}
            disableUnderline
            sx={{
              fontSize: 13,
              ml: 1,
            }}
            onChange={(e) => setDeleteText(e.target.value)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          style={{ gap: 8, marginTop: 12, marginBottom: 18 }}
          flexWrap="nowrap"
        >
          <Button
            sx={{
              textTransform: 'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              selected.forEach((entity: any): void => {
                handleDelete(entity.id)
              })
            }
            }
            disabled={confirmButtonDisabled}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  )
}