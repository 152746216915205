import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Checkbox, FormHelperText, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { fetchChargePoints } from '../../../services/ChargePointService';
import { fetchLoadGroup, patchLoadGroup } from '../../../services/LoadGroupService';
import { fetchLocations } from '../../../services/LocationService';
import React from 'react'
import { UpdateProps } from '../../../react-app-env';

export default function LoadGroupUpdate({ closeSideSheet, setRefresh, entity, refresh }: UpdateProps) {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [ChargePoints, setChargers] = useState<any[]>([])
    const [Locations, setLocations] = useState<any[]>([])
    const [errors, setErrors] = useState<{ nameError?: string; typeError?: string; locationError?: string; unauthorizedError?: string }>({});

    const [selectedLoadGroup, setSelectedLoadGroup] = useState<any>()
    const [currentLocation, setCurrentLocation] = useState<any>();
    const [selectedChargers, setSelectedChargers] = useState<any>([]);
    const [OG, setOG] = useState<any>();

    useEffect(() => {
        const getLocations = async () => {
            const locationsfromserver = await fetchLocations()
            setLocations(locationsfromserver)
        }
        const getChargers = async () => {
            const chargersfromserver = await fetchChargePoints()
            setChargers(chargersfromserver)
        }
        getChargers()
        getLocations()
    }, []);

    useEffect(() => {
        const getSelected = async () => {
            const Selected = await fetchLoadGroup(entity);
            setSelectedLoadGroup(Selected)
            setCurrentLocation(Selected?.location)
            setSelectedChargers(Selected?.chargePoints?.map((a: { id: any; }) => a.id))
            setOG(Selected)
            setLoading(false)
        }
        getSelected()
    }, [entity]);

    const validateLoadGroup = () => {
        let e = { ...errors };
        let valid = false;
        if (parseInt(selectedLoadGroup?.staticMaxLoad)) {
            e.typeError = undefined;
            valid = true;
        } else {
            valid = false;
            e.typeError = "Enter an integer value";
        }
        if (typeof currentLocation == 'undefined') {
            e.locationError = "Select a location";
            valid = false;
        } else {
            e.locationError = undefined;
            valid = true;
        }
        if (selectedLoadGroup?.name.length < 3) {
            e.nameError = "Enter a name with at least 3 characters.";
            valid = false;
        } else {
            e.nameError = undefined;
            valid = true;
        }
        setErrors(e);
        return valid;
    }

    const handleCancel = () => {
        closeSideSheet();
    }

    const handleChange = (event: SelectChangeEvent<typeof selectedChargers>) => {
        const {
            target: {
                value },
        } = event;

        event.target.value.indexOf("") > -1 ? setSelectedChargers([]) :
            setSelectedChargers(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
    };

    const UpdateField = (requestBody: Object[], current: string | number, updated: string | number, path: string) => {
        if (JSON.stringify(current) !== JSON.stringify(updated)) {
            requestBody.push
                ({
                    "op": "replace",
                    "path": path,
                    "value": updated
                });
        }
    }

    const handleClick = async () => {

        if (validateLoadGroup()) {
            const reqBody: Object[] = [];
            UpdateField(reqBody, OG.name, selectedLoadGroup.name, "/name")
            UpdateField(reqBody, OG.location?.id, currentLocation.id, "/location")
            if (selectedChargers.includes("")) {
                reqBody.push({
                    "op": "remove",
                    "path": "/chargepoints"
                })
            } else {
                UpdateField(reqBody, OG.chargePoints.map((a: { id: string; }) => a.id), selectedChargers, "/chargepoints")
            }
            UpdateField(reqBody, OG.description, selectedLoadGroup.description, "/description")
            UpdateField(reqBody, OG.staticMaxLoad, selectedLoadGroup.staticMaxLoad, "/staticmaxload")
            // console.log(reqBody)
            // POST request 
            const response = await patchLoadGroup(entity, reqBody)
            if (response?.ok) {
                setRefresh(!refresh);
                closeSideSheet();
            } else {
                if (response?.status === 401 || response?.status === 403) {
                    errors.unauthorizedError = "Du har ikke rettighed til at rette i tags på en entity";
                }
                else {
                    errors.unauthorizedError = undefined;
                }
            }
        }
    }

    if (isLoading) {
        return <Typography
            sx={{
                mt: 2,
                color: "#030F4F",
                fontSize: 25
            }}
        >
            Loading...
        </Typography>
    }

    return (
        <React.Fragment>
            <Box flex={1}  >
                <Box>
                    <Typography sx={{ mt: 2, color: "#030F4F", fontSize: 25 }}>
                        Edit Load Group
                    </Typography>
                    <Typography sx={{ mt: 1, color: "#030F4F" }}>
                        Guid: {entity}
                    </Typography>
                </Box>

                <Box display='flex' flexDirection="column">
                    <TextField
                        required
                        error={typeof errors.nameError !== 'undefined'}
                        variant="standard"
                        size="small"
                        onChange={(e) => setSelectedLoadGroup((selectedLoadGroup: any) =>
                        ({
                            ...selectedLoadGroup, "name": e.target.value
                        })
                        )}
                        value={selectedLoadGroup?.name ?? ""}
                        label="Name"
                        placeholder={"Enter name here"}
                        sx={{ mt: 2, width: '16vw' }}
                        helperText={errors.nameError}
                    />
                    <TextField
                        variant="standard"
                        value={selectedLoadGroup?.description ?? ""}
                        onChange={(e) => {
                            setSelectedLoadGroup((selectedLoadGroup: any) => ({
                                ...selectedLoadGroup, "description": e.target.value
                            }))
                        }}
                        multiline
                        rows={2}
                        label="Description"
                        placeholder={"Enter description here"}
                        sx={{ mt: 2, width: '16vw' }}
                    />
                    <FormControl variant="standard" required sx={{ mt: 2, width: '16vw' }} error={typeof errors.locationError !== 'undefined'}>
                        <InputLabel required size="small" id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            size="small"
                            label="Location"
                            id="demo-multiple-name"
                            value={currentLocation?.id}
                            onChange={(e) => {
                                setCurrentLocation(Locations.find(l => l.id === e.target.value));
                                setSelectedChargers([])
                            }
                            }
                        >
                            {Locations.map((single) => (
                                <MenuItem
                                    key={single.id}
                                    value={single.id}>
                                    {single.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ mt: 2, width: '16vw' }}>
                        <InputLabel shrink size="small" id="demo-simple-select-label">Charge Points</InputLabel>
                        <Select
                            displayEmpty
                            size="small"
                            labelId="select-multiple-name-edit"
                            id="demo-multiple-name"
                            multiple
                            value={selectedChargers}
                            onChange={handleChange}
                            startAdornment={currentLocation ? null :
                                <Tooltip title="Select a location first" placement="left">
                                    <InputAdornment position="start">
                                        <InfoIcon></InfoIcon>
                                    </InputAdornment>
                                </Tooltip>}
                            renderValue={(selected) => {
                                let result = selected.map(
                                    (obj: any) =>
                                    (
                                        ChargePoints.filter((e: { id: any; }) => e.id === obj)
                                            .map((x: { displayName: any; chargePointId: string }) => x.chargePointId)))
                                    .join(", ");
                                return result;
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {ChargePoints.filter((x: { location: { id: any; }; }) => x.location?.id === currentLocation?.id).map((single: { id: any; name: any; chargePointId: string; }) => (
                                <MenuItem
                                    key={single.id}
                                    value={single.id}
                                    dense
                                >
                                    <Checkbox checked={selectedChargers.indexOf(single.id) > -1} />
                                    <ListItemText primary={single.chargePointId} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl error={!selectedLoadGroup.staticMaxLoad} variant="standard" sx={{ mt: 2, width: '12vw' }}>
                        <InputLabel required shrink >Max Load (Ampere)</InputLabel>
                        <Input
                            type="number"
                            size="small"
                            onChange={(e) => {
                                setSelectedLoadGroup((selectedLoadGroup: any) => ({
                                    ...selectedLoadGroup, "staticMaxLoad": e.target.value
                                }))
                            }}
                            placeholder={"Enter max load"}
                            value={selectedLoadGroup?.staticMaxLoad ?? ""}
                            sx={{ mt: 2, width: '6vw' }}
                            endAdornment={<InputAdornment position="end">A</InputAdornment>}
                        />
                        <FormHelperText error={typeof errors.typeError !== 'undefined'}>
                            {errors.typeError}
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Box>
            <Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ gap: 1, mt: 6, mb: 9 }}
                    flexWrap="nowrap"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 0,
                            padding: "0px 8px",
                            whiteSpace: "nowrap",
                            minWidth: "max-content",
                        }}
                    >
                        Update
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 0,
                            padding: "0px 8px",
                            whiteSpace: "nowrap",
                            minWidth: "max-content",
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    )
}