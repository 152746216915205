import MessageListView from './MessageListView';
import * as React from 'react'
import { Box } from '@mui/material';

export default function MessageListPage() {
    return (
        <Box display='flex' flex={1} sx={{ height: "100%" }}>
            <Box display='flex' flex={1} flexDirection="column">
                <MessageListView />
            </Box>
        </Box>
    );
}