import React from 'react'
import EvStationIcon from '@mui/icons-material/EvStation';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import MapIcon from '@mui/icons-material/Map';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { Divider, Drawer, styled, Tab, Tabs, Toolbar } from '@mui/material';
import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import MessageIcon from '@mui/icons-material/Message';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SimCardIcon from '@mui/icons-material/SimCard';
import { Menu, MenuButton, NavigationButton } from 'prolonreactcomponents';

//TODO: Change to styled tabs in order to fulfill DRY principle
//check theme.tsx

function NavigationSideMenu() {
  
    return (
        <Menu>
            <Divider variant='fullWidth' textAlign='left' sx={{ fontSize: '13px', py: 0.5 }}>Hub Configuration</Divider>
            <NavigationButton
                text={"Locations"}
                icon={<MapIcon color='success' />}
                to={"Locations"}
            />
            <NavigationButton
                text={"Charge Points"}
                icon={<EvStationIcon color='warning' />}
                to={"ChargePoints"}
            />
            <NavigationButton
                text={"Load Groups"}
                icon={<ElectricMeterIcon color='primary' />}
                to={"LoadGroups"}
            />
            <NavigationButton
                text={"Service Providers"}
                icon={<ElectricalServicesIcon color='secondary' />}
                to={"ServiceProviders"}
            />

            <Divider variant='fullWidth' textAlign='left' sx={{ fontSize: '13px', py: 0.5 }}>Monitoring</Divider>
            <NavigationButton
                text={"Messages"}
                icon={<MessageIcon sx={{ color: '#fbc02d' }} />}
                to={"Messages"}
            />
            <NavigationButton
                text={"Transactions"}
                icon={<ReceiptIcon sx={{ color: '#2196f3' }} />}
                to={"Transactions"}
            />

            <Divider variant='fullWidth' textAlign='left' sx={{ fontSize: '13px', py: 0.5 }}>Connections</Divider>
            <NavigationButton
                text={"SIM Cards"}
                icon={<SimCardIcon sx={{ color: '#f50057' }} />}
                to={"SIMCards"}
            />
        </Menu>
    )
}

export default NavigationSideMenu