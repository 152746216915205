import React, { ReactElement } from "react";
import { useAuth, AuthContextProps } from "react-oidc-context";

export let auth: AuthContextProps;

type AuthorizationContext = {
    children: React.ReactNode
}
export default function AuthorizationContext({ children }: AuthorizationContext) {

    auth = useAuth();
    console.log("AuthorizationContext rendered");
    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <>
                {children}
            </>
        ) as ReactElement;
    }
    else {
        auth.signinRedirect();
    }
    return <div />
}
