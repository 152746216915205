import CommentIcon from '@mui/icons-material/Comment';
import { Alert } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState, ReactNode } from 'react';
import React from 'react'

type CheckboxListProps = {
  items : string[];
  checkedItems : string[];
  setChecked : (SideSheet: React.ReactNode | null) => void
}

export default function CheckboxList({items, checkedItems, setChecked}: CheckboxListProps) {
  const [showDes, setShowDes] = useState(false)
  const handleToggle = (value: string) => () => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {items?.length > 0 ? items.map((value : any) => {
        return (
          <ListItem
            key={value.name}
            secondaryAction={
              <IconButton edge="end" aria-label="comments" onClick={() => {setShowDes(true)}}>
                <CommentIcon />
                {showDes ? value['description'] : null}
              </IconButton>
            }
            disablePadding>
            <ListItemButton onClick={handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  key = {value.name}
                  edge="start"
                  checked={checkedItems.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
                <ListItemText primary={value.name} />
            </ListItemButton>
          </ListItem>
        );
      }) : <Alert severity="info">There are no capabilities to show</Alert>}
    </List>
  );
}