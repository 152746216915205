import { Box } from '@mui/material';

export type SideSheetProps = {
  children: React.ReactNode,
}

const SideSheet = ({ children } : SideSheetProps) => {
  return (
    <Box component="span" sx={{ pl: 3, mt: -1, width: '400px', display: 'flex', flexDirection: 'column', zIndex: 1200, backgroundColor: "#FFFFFF" }} boxShadow={5}>
      {children}
    </Box>
  );
}
export default SideSheet