import React from 'react'
import { Box } from '@mui/material';
import { useState } from 'react';
import LoadGroupListView from './LoadGroupListView';

export default function LoadGroupListPage() {
  const [sideSheet, setSideSheet] = useState<React.ReactNode |null>(null);
  const [searchValue, setSearchValue] = useState([]);
  
  return (
    <Box display='flex' flex={1} sx={{ height: "100%" }}>
      <Box display='flex' flex={1} flexDirection="column">
        <LoadGroupListView setSideSheet={setSideSheet}/>
      </Box>
      {sideSheet}
    </Box>
  )
}