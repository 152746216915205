import { baseAddress } from "../Config";
export async function fetchServiceProviders() {
  try {
    const resp = await fetch(baseAddress + '/serviceproviders', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const data = await resp.json()
    return data
  }
  catch (e) {
    console.error(e);
  }
}

export async function fetchServiceProvider(id: string) {
  try {
    const resp = await fetch(baseAddress + `/serviceproviders/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const data = await resp.json()
    return data
  }
  catch (e) {
    console.error(e);
  }
}

export async function deleteServiceProvider(id: string) {
  try {
    const resp = await fetch(baseAddress + `/serviceproviders/${id}`,
      {
        method: 'DELETE',
        headers:
        {
          'Content-Type': 'application/json'
        },
      });
    return resp.ok
  }
  catch (e) {
    console.error(e);
  }
}
export async function postServiceProvider(reqBody : Object) {
  try {
      const response = await fetch(baseAddress + "/serviceproviders", {
        method: 'POST',
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(reqBody)
      });
      return response;
  }
  catch (e) {
    console.error(e);
  }
}

export async function patchServiceProvider(id: string, reqBody : Object) {
  try {
      const response = await fetch(baseAddress + `/serviceproviders/${id}`, {
        method: 'PATCH',
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(reqBody)
      });
      return response;
  }
  catch (e) {
    console.error(e);
  }
}