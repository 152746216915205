import React from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { CreateProps } from '../../../react-app-env';
import { postLocation } from '../../../services/LocationService';

export default function LocationCreate({ closeSideSheet, setRefresh, refresh }: CreateProps) {
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("0");
  const [longitude, setLongitude] = useState<string>("0");
  const [errors, setErrors] = useState<{nameError? : string; deleteError?: string; postalCodeError?:string; unauthorizedError?:string}>({});

  const handleCancel = () => {
    closeSideSheet();
  }

  const handleClick = async () => {
    if (validateForm()) {
      let reqBody = {
        "name": name,
        "street": street,
        "city": city,
        "postalCode": postalCode,
        "coord": {
          "latitude": latitude,
          "longitude": longitude
        }
      }
      const response = await postLocation(reqBody)
      if (response?.ok) {
        setRefresh(!refresh);
        closeSideSheet();
      } else {
        if (response?.status === 401 || response?.status === 403) {
          errors.unauthorizedError = "Du har ikke rettighed til at rette i tags på en entity";
        }
      }
    } 
  }
  const validateForm = () => {
    let e = { ...errors };
    let valid = false;
    if (typeof name !== 'undefined' && name.length < 3) {
      e.nameError = "Please enter a name with at least 3 characters.";
      valid = false;
    } else {
      e.nameError = undefined;
      valid = true;
    }
    if (postalCode !== "") {
      if (postalCode.length === 4 && /^\d+$/.test(postalCode)) {
        e.postalCodeError = undefined;
        valid = true;
      }
      else {
        valid = false;
        e.postalCodeError = "Please enter a valid postal code.";
      }
    }
    setErrors(e);
    return valid;
  }

  return (
    <>
      <Box flex={1} overflow={'auto'}>
        <Typography
          sx={{
            mt: 2,
            color: "#030F4F",
            fontSize: 25
          }}
        >
          Add New Location
        </Typography>
        <Box display='flex' flexDirection="column">
          <TextField
            required
            error={typeof errors.nameError !== 'undefined'}
            variant="standard"
            size="small"
            onChange={(e) => setName(e.target.value)}
            value={name}
            label="Name"
            placeholder={"Enter name here"}
            sx={{ mt: 2, width: '16vw' }}
            helperText={errors.nameError}
          />
          <TextField
            variant="standard"
            size="small"
            value={street}
            onChange={(e) => { setStreet(e.target.value) }}
            label="Street"
            placeholder={"Enter street name here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <TextField
            error={typeof errors.postalCodeError !== 'undefined'}
            variant="standard"
            size="small"
            onChange={(e) => setPostalCode(e.target.value)}
            value={postalCode}
            label="Postal Code"
            placeholder={"Enter postal code here"}
            sx={{ mt: 2, width: '16vw' }}
            helperText={errors.postalCodeError}
          />
          <TextField
            variant="standard"
            value={city}
            size="small"
            onChange={(e) => { setCity(e.target.value) }}
            id="outlined-multiline-static"
            label="City"
            placeholder={"Enter city name here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                type="number"
                variant="standard"
                size="small"
                onChange={(e) => { setLatitude(e.target.value) }}
                value={latitude}
                id="outlined-location-postal-code"
                label="Latitude"
                placeholder={"Enter latitude here"}
                sx={{ mt: 2, width: '5vw' }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="number"
                variant="standard"
                size="small"
                onChange={(e) => { setLongitude(e.target.value) }}
                value={longitude}
                id="outlined-location-postal-code"
                label="Longitude"
                placeholder={"Enter longitude here"}
                sx={{ mt: 2, width: '5vw' }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ gap: 1, mt: 6, mb: 9 }}
          flexWrap="nowrap"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"

            onClick={handleCancel}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}