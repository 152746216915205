import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/system';
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#030F4F",
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
          },
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#030F4F",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          textTransform: "none",
          flexBasis: 1,
          fontSize: 13,
          padding: "0px 8px",
          whiteSpace: "nowrap",
          minWidth: "max-content",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        // Name of the slot
        root: {
          opacity: 1,
          textTransform: "none",
          marginTop: 24,
          borderColor: 'divider',
          fontSize: '12px',
          justifyContent: 'left',
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: "#000000",
          textTransform: "none",
          minHeight: 36,
          maxHeight: 36,
          justifyContent: 'left',
          py: 0,
          '&.Mui-selected': {
            '&, &:hover': {
              color: "#000000",
              backgroundColor: '#f0f8ff',
            },
          },

        }, labelIcon: {

          justifyContent: 'left',

        },
        selected: {

        },
        wrapper: {
          lineHeight: '18px',
          textTransform: 'uppercase',
          fontSize: '12px',
          position: 'relative',
          display: 'block',
          color: 'inherit',
        },
      }
    }
  }
});


export default theme;