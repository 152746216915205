import * as React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Alert, Box, Button, Fade, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridLinkOperator, GridToolbarQuickFilter, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import ActionBarButton from '../../components/ActionBarButton';
// import CustomDivider from '../../components/CustomDivider';
import SideSheet from '../../components/SideSheet';
import ChargePointCreate from './SideSheets/ChargePointCreate';
import ChargePointUpdate from './SideSheets/ChargePointUpdate';
// import DeleteEntity from '../../components/SideSheets/DeleteEntity';
import { fetchChargePoints } from '../../services/ChargePointService';
import { ListViewProps } from '../../react-app-env';
import DeleteEntity from '../../components/SideSheets/DeleteEntity';
import CustomDivider from '../../components/CustomDivider';
import { Refresh } from '@mui/icons-material';
import { ActionBar, ActionButton } from 'prolonreactcomponents';
import GridLocalSearchField from '../../components/GridLocalSearchField';

export default function ChargePointListView({ setSideSheet }: ListViewProps) {
    const [selectionModel, setSelectionModel] = useState<any[]>([])
    const [Chargers, setChargers] = useState<any[]>([])
    const [refresh, setRefresh] = useState(false);
    const [errors, setErrors] = useState(
        {
            deleteError: null,
            fetchError: null,
        }
    );
    const getChargers = async () => {
        const chargersfromserver = await fetchChargePoints()
        setChargers(chargersfromserver)
    }

    useEffect(() => {
        getChargers()
    }, [refresh])

    const columns: GridColDef[] =
        [
            {
                field: 'chargePointId',
                headerName: 'Identifier',
                type: 'string',
                width: 175
            },
            {
                field: 'displayName',
                headerName: 'Name',
                type: 'string',
                width: 175
            },
            {
                field: 'location',
                headerName: 'Location',
                type: 'string',
                width: 175,
                valueGetter: (params: GridValueGetterParams) => params.row.location != null ? params.row.location.name : "No location"
            },
            {
                field: 'loadGroup',
                headerName: 'Load Group',
                type: 'string',
                width: 175,
                valueGetter: (params: GridValueGetterParams) => params.row.loadGroup != null ? params.row.loadGroup.name : "No load group assigned"
            },
            {
                field: 'connection',
                headerName: 'Connection',
                type: 'string',
                width: 100
            },
            {
                field: 'status',
                headerName: 'Status',
                type: 'string',
                width: 100
            },
            {
                field: 'model',
                headerName: 'Model',
                type: 'string',
                width: 180
            },
            {
                field: 'vendor',
                headerName: 'Vendor',
                type: 'string',
                width: 175
            },
            {
                field: 'serialNo',
                headerName: 'Serial No.',
                type: 'string',
                width: 175,
                hide: true
            },
            {
                field: 'firmwareVersion',
                headerName: 'Firmware',
                type: 'string',
                width: 175
            },
            {
                field: 'type',
                headerName: 'Type',
                type: 'string',
                width: 175,
                hide: true
            },
            {
                field: 'imsi',
                headerName: 'IMSI',
                type: 'string',
                width: 175,
                hide: true
            },
            {
                field: 'iccd',
                headerName: 'ICCD',
                type: 'string',
                width: 175,
                hide: true
            }
        ];
    const handleEditCharger = (id: string) => {
        setSideSheet(<SideSheet><ChargePointUpdate closeSideSheet={closeSideSheet} setRefresh={setRefresh} entity={id} refresh={refresh}></ChargePointUpdate> </SideSheet>)
    }

    const handleNewCharger = () => {
        setSideSheet(<SideSheet><ChargePointCreate closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh}></ChargePointCreate> </SideSheet>)
    }

    const closeSideSheet = () => {
        setSelectionModel([]);
        setSideSheet(null);
    }

    const handleDelete = () => {
        let selectedEntities = Chargers.filter(x => selectionModel.includes(x.id));
        setSideSheet(<SideSheet><DeleteEntity selected={selectedEntities} closeSideSheet={closeSideSheet} setRefresh={setRefresh} type={"chargepoint"} refresh={refresh}></DeleteEntity> </SideSheet>)
    }

    const ToolBar= () => {
        return (
            <Box>
                <ActionBar paddingBottom={0} marginLeft={5}>
                    <ActionButton text={"Add New Charger"} action={handleNewCharger} icon={<AddIcon />} />
                    <ActionButton text={"Delete"} action={handleDelete} icon={<DeleteOutlineIcon />} disabled={selectionModel.length == 0} />
                    <ActionButton
                        text="Refresh"
                        action={() => {
                            getChargers();
                        }}
                        icon={<Refresh />}
                    />

                    <GridLocalSearchField />
                </ActionBar>
            </Box>
        )
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <Box style={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                <Typography variant='h6' sx={{
                    pl: 2,
                    py: 1
                }} >
                    Charge Points
                </Typography>
                <DataGridPro
                    rows={Chargers}
                    columns={columns}
                    density='compact'
                    autoPageSize
                    pagination
                    checkboxSelection
                    disableSelectionOnClick
                    selectionModel={selectionModel}
                    onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                    }}
                    onCellClick={(params, event) => {
                        handleEditCharger(params.row.id);
                    }}
                    rowHeight={30}
                    components={{
                        Toolbar: ToolBar,
                    }}
                    initialState={{
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterLogicOperator: GridLinkOperator.And,

                            },

                        },
                    }}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            // backgroundColor: "transparent",
                            "& .MuiDataGrid-cell": {
                                padding: 0
                            }
                        },
                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "whitesmoke"
                            },
                            "&:first-of-type": {
                                borderTop: "1px solid rgba(224, 224, 224, 1)"
                            }
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none"
                        }
                    }}
                />
            </Box>
        </Box>
    );
}
