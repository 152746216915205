import React from 'react'
import { ListViewProps } from '../../react-app-env';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Alert, Box, Button, Fade, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import ActionBarButton from '../../components/ActionBarButton';
import CustomDivider from '../../components/CustomDivider';
import SideSheet from '../../components/SideSheet';
import DeleteEntity from '../../components/SideSheets/DeleteEntity';
import ServiceProviderCreate from './SideSheets/ServiceProviderCreate';
import ServiceProviderUpdate from './SideSheets/ServiceProviderUpdate';
import { fetchServiceProviders } from '../../services/ServiceProviderService';
import { ActionBar, ActionButton } from 'prolonreactcomponents';
import GridLocalSearchField from '../../components/GridLocalSearchField';

export default function ServiceProviderListView({ setSideSheet }: ListViewProps) {
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'string',
            width: 200
        },
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            width: 175
        },

        {
            field: 'description',
            headerName: 'Description',
            type: 'string',
            width: 250
        },
        {
            field: 'endpoint',
            headerName: 'Endpoint',
            type: 'string',
            width: 200
        }
    ];
    const [ServiceProviders, setServiceProviders] = useState([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<any[]>([])
    const [searchValue, setSearchValue] = useState([])
    const [errors, setErrors] = useState(
        {
            deleteError: null,
            fetchError: null,
        }
    );

    useEffect(() => {
        const getServiceProviders = async () => {
            const ServiceProvidersfromserver = await fetchServiceProviders()
            setServiceProviders(ServiceProvidersfromserver)
        }
        getServiceProviders()
    }, [refresh])

    const closeSideSheet = () => {
        setSideSheet(null);
    }
    const handleEditServiceProvider = (id: string) => {
        setSideSheet(<SideSheet><ServiceProviderUpdate closeSideSheet={closeSideSheet} setRefresh={setRefresh} entity={id} refresh={refresh}></ServiceProviderUpdate> </SideSheet>)
    }
    const handleNewServiceProvider = () => {
        setSideSheet(<SideSheet><ServiceProviderCreate closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh}></ServiceProviderCreate> </SideSheet>)
    }
    const handleDelete = () => {
        let selectedEntities = ServiceProviders.filter(x => selectionModel.includes(x['id']));
        setSideSheet(<SideSheet><DeleteEntity selected={selectedEntities} closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh} type={"serviceprovider"}></DeleteEntity> </SideSheet>)
    }

    const ToolBar = () => {
        return (
            <Box>
                <ActionBar paddingBottom={0} marginLeft={5}>
                    <ActionButton text={"Add New Service Provider"} action={handleNewServiceProvider} icon={<AddIcon />} />
                    <ActionButton text={"Delete"} action={handleDelete} icon={<DeleteOutlineIcon />} disabled={!toggle} />

                    <GridLocalSearchField />
                </ActionBar>
            </Box>
        )
    }


    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <Box style={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                <Typography variant='h6' sx={{
                    pl: 2,
                    py: 1
                }} >
                    Service Providers
                </Typography>
                <DataGridPro
                    disableSelectionOnClick
                    checkboxSelection
                    rows={ServiceProviders}
                    columns={columns}
                    density='compact'
                    autoPageSize
                    pagination
                    selectionModel={selectionModel}
                    onRowClick={(params, event) => {
                        handleEditServiceProvider(params.row.id)
                    }
                    }
                    onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        ids.length > 0 ? setToggle(true) : setToggle(false);
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide id on default but allow user to view
                                id: false,
                            },
                        },
                    }}
                    components={{
                        Toolbar: ToolBar
                    }}
                    rowHeight={30}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            // backgroundColor: "transparent",
                            "& .MuiDataGrid-cell": {
                                padding: 0
                            }
                        },
                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "whitesmoke"
                            },
                            "&:first-of-type": {
                                borderTop: "1px solid rgba(224, 224, 224, 1)"
                            }
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none"
                        }
                    }}
                />
            </Box>
        </Box>
    )
}