import { GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React from "react";


export default function GridLocalSearchField() {
    return (
        <GridToolbarQuickFilter
            quickFilterParser={(searchInput: string) =>
                searchInput
                    .split(',')
                    .map((value) => value.trim())
                    .filter((value) => value !== '')
            }
            placeholder="Søg..."
            sx={{
                paddingBottom: 0,
                '& .css-ptiqhd-MuiSvgIcon-root': {
                    color: '#0078D4'
                },
                '& .css-c63i49-MuiInputBase-input-MuiInput-input': {
                    fontSize: 13,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                '& .css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                    height: 22.75,
                    width: 170,
                    BorderBottom: 0,
                    borderBottomColor: 'transparent',
                    borderImageWidth: 0,
                },
                '& .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before': {
                    borderBottom: 0
                },
                '& .css-fp9tcu-MuiButtonBase-root-MuiIconButton-root': {
                    padding: 0,
                },
                '& .MuiInput-underline': {
                    borderBottom: 0,
                    ':hover:before': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                    ':hover:after': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                    ':before': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                    ':after': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                },
            }}
        />
    );
}