import { baseAddress } from "../Config";

export async function fetchLoadGroups() {
    try {
        const resp = await fetch(baseAddress + '/LoadGroups',
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

        const data = await resp.json()
        return data
    } catch (e) {
        console.error(e);
    }
}

export async function fetchLoadGroup(id: string) {
    try {
        const resp = await fetch(baseAddress + "/loadgroups/" + id,
            {
                method: 'GET',
                headers:
                {
                    'Content-Type': 'application/json'
                },
            });
        const data = await resp.json()
        return data
    } catch (e) {
        console.error(e);
    }

}
export async function postLoadGroup(reqBody: Object) {
    // console.log(chargePointId)
    try {
        const resp = await fetch(baseAddress + "/loadgroups/",
            {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
            });
        return resp
    } catch (e) {
        console.error(e);
    }
}

export async function patchLoadGroup(id: string, reqBody: Object) {
    // console.log(chargePointId)
    try {
        const resp = await fetch(baseAddress + "/loadgroups/" + id,
            {
                method: 'PATCH',
                headers:
                {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
            });
        return resp
    } catch (e) {
        console.error(e);
    }
}


export async function deleteLoadGroup(id: string) {
    try {
        const resp = await fetch(baseAddress + "/loadgroups/" + id,
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });
        return resp
    }
    catch (e) {
        console.error(e);
    }
}