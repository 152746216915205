import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Checkbox, InputAdornment, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { CreateProps } from '../../../react-app-env';
import { postChargePoint } from '../../../services/ChargePointService';
import { fetchLoadGroups } from '../../../services/LoadGroupService';
import { fetchLocations } from '../../../services/LocationService';
import { fetchServiceProviders } from '../../../services/ServiceProviderService';
import SaveIcon from '@mui/icons-material/Save';

export default function ChargePointCreate({ closeSideSheet, setRefresh, refresh }: CreateProps) {
    const [isLoading, setLoading] = useState(true);
    const [Locations, setLocations] = useState<any[]>([])
    const [LoadGroups, setLoadGroups] = useState<any[]>([])
    const [ServiceProviders, setServiceProviders] = useState<any[]>([])
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [selectedLoadGroup, setSelectedLoadGroup] = useState<any>();
    const [chargePointId, setChargePointId] = useState<string>("");
    const [displayName, setDisplayName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [clientCertThumb, setClientCertThumb] = useState<string>("");
    const [selectedServiceProviders, setSelectedServiceProviders] = useState<string[]>([]);
    const [errors, setErrors] = useState<{ nameError?: string; deleteError?: string; unauthorizedError?: string }>({});

    const handleCancel = () => {
        closeSideSheet();
    }

    const handleClick = async () => {

        if (validateNameChange()) {
            // console.log(selectedServiceProviders)
            const reqBody = {
                "chargePointId": chargePointId,
                "displayName": displayName,
                "description": description,
                "username": username,
                "password": password,
                "clientCertThumb": clientCertThumb,
                "locationId": selectedLocation?.id ?? null,
                "loadGroupId": selectedLoadGroup?.id ?? null,
                "serviceProviderIds": selectedServiceProviders ?? null
            };
            // POST request
            const response = await postChargePoint(reqBody)
            // console.log(response.status)
            if (response?.ok) {
                setRefresh(!refresh);
                closeSideSheet();
            } else {
                if (response?.status === 401 || response?.status === 403) {
                    setErrors({ unauthorizedError: "Du har ikke rettighed til at rette i tags på en entity" });
                }
            }
        }
        // console.log("Created new charger: " + data);
    }

    useEffect(() => {
        const getLocations = async () => {
            const locationsfromserver = await fetchLocations()
            setLocations(locationsfromserver)
        }
        const getLoadGroups = async () => {
            const loadgroups = await fetchLoadGroups()
            setLoadGroups(loadgroups)
        }
        const getServiceProviders = async () => {
            const serviceprov = await fetchServiceProviders()
            setServiceProviders(serviceprov)
            setLoading(false)
        }
        getLocations()
        getLoadGroups()
        getServiceProviders()
    }, []);

    const validateNameChange = () => {
        let e = { ...errors };
        if (chargePointId.length < 3) {
            e.nameError = "Please enter an identifier";
            setErrors(e);
            return false;
        } else {
            e.nameError = undefined;
            setErrors(e);
            return true;
        }

    }

    const handleServiceProviderChange = (event: SelectChangeEvent<typeof selectedServiceProviders>) => {
        const {
            target: { value }
        } = event;
        setSelectedServiceProviders(
            typeof value === "string" ? value.split(",") : value
        );
    };
    if (isLoading) {
        return <Typography
            sx={{
                mt: 2,
                color: "#030F4F",
                fontSize: 25
            }}
        >
            Loading...
        </Typography>
    }
    return (
        <>
            <Box flex={1} component="span" sx={{ display: 'block' }} >
                <Typography
                    sx={{
                        mt: 2,
                        color: "#030F4F",
                        fontSize: 25
                    }}
                >
                    Add New Charger
                </Typography>

                <Box display='flex' flexDirection="column">
                    <TextField
                        required
                        error={typeof errors.nameError !== 'undefined'}
                        variant="standard"
                        size="small"
                        onChange={(e) => setChargePointId(e.target.value)}
                        value={chargePointId}
                        label="Identifier"
                        placeholder={"Enter identifier here"}
                        sx={{ mt: 2, width: '16vw' }}
                        helperText={errors.nameError}
                    />
                    <TextField
                        required
                        variant="standard"
                        size="small"
                        onChange={(e) => setDisplayName(e.target.value)}
                        value={displayName}
                        label="Display Name"
                        placeholder={"Enter name here"}
                        sx={{ mt: 2, width: '16vw' }}
                    />
                    <TextField
                        variant="standard"
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }}
                        multiline
                        rows={2}
                        label="Description"
                        placeholder={"Enter description here"}
                        sx={{ mt: 2, width: '16vw' }}
                    />
                    <FormControl variant="standard" sx={{ mt: 2, width: '16vw' }} >
                        <InputLabel shrink size="small" id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            displayEmpty
                            size="small"
                            label="Location"
                            id="demo-multiple-name"
                            value={selectedLocation?.id ?? ""}
                            onChange={(e) => {
                                setSelectedLocation(Locations.find(l => l.id === e.target.value));
                                setSelectedLoadGroup("")
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {Locations.map((single) => (
                                <MenuItem
                                    key={single.id}
                                    value={single.id}
                                >
                                    {single.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ mt: 2, width: '16vw' }}
                        id="standard-select"
                        select
                        label="Load Group"
                        value={selectedLoadGroup?.id ?? ""}
                        onChange={(e) => {
                            setSelectedLoadGroup(LoadGroups.find(l => l.id === e.target.value))
                        }}
                        variant="standard"
                        InputProps={{
                            startAdornment: selectedLocation ? null :
                                <Tooltip title="Select a location first" placement="left">
                                    <InputAdornment position="start">
                                        <InfoIcon></InfoIcon>
                                    </InputAdornment>
                                </Tooltip>
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {LoadGroups.filter(x => x.location.id === selectedLocation?.id).map((single) => (
                            <MenuItem
                                key={single.id}
                                value={single.id}
                            >
                                {single.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        variant="standard"
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        size="small"
                        label="Username"
                        placeholder={"Enter username"}
                        sx={{ mt: 2, width: '16vw' }}
                    />
                    <TextField
                        type="password"
                        variant="standard"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        size="small"
                        label="Password"
                        placeholder={"Enter password"}
                        sx={{ mt: 2, width: '16vw' }}
                    />
                    <TextField
                        variant="standard"
                        value={clientCertThumb}
                        onChange={(e) => { setClientCertThumb(e.target.value) }}
                        size="small"
                        label="Client Cert Thumb"
                        placeholder={"Enter your client certification thumb"}
                        sx={{ mt: 2, width: '16vw' }}
                    />
                    <FormControl variant="standard" sx={{ mt: 2, width: '16vw' }} >
                        <InputLabel shrink size="small" >Service Providers</InputLabel>
                        <Select
                            multiple
                            displayEmpty
                            size="small"
                            label="Load Group"
                            value={selectedServiceProviders}
                            renderValue={(selected) =>
                                selected.map((obj) => (ServiceProviders.filter(e => e.id === obj).map(x => x.name))).join(", ")

                            }
                            onChange={handleServiceProviderChange}
                        // setSelectedServiceProviders(e.target.value)
                        >
                            {ServiceProviders.map((single) => (
                                <MenuItem
                                    key={single.id}
                                    value={single.id}
                                >
                                    <Checkbox checked={selectedServiceProviders.indexOf(single.id) > -1} />
                                    <ListItemText primary={single.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            {/* WIP potentially use loading button */}
            <Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ gap: 1, mt: 6, mb: 3 }}
                    flexWrap="nowrap"
                >
                    <Button
                        startIcon={<SaveIcon />}
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 0,
                            padding: "0px 8px",
                            whiteSpace: "nowrap",
                            minWidth: "max-content",
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 0,
                            padding: "0px 8px",
                            whiteSpace: "nowrap",
                            minWidth: "max-content",
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </>
    );
}