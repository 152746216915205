import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LocationListPage from './pages/Locations/LocationListPage';
import ChargePointListPage from './pages/ChargePoints/ChargePointListPage';
import ServiceProviderListPage from './pages/ServiceProviders/ServiceProviderListPage';
import LoadGroupListPage from './pages/LoadGroups/LoadGroupListPage';
import TransactionListPage from './pages/Transactions/TransactionListPage';
import MessageListPage from './pages/Messages/MessageListPage';
import { AuthProviderProps } from 'react-oidc-context';
import Authorization from './components/Authorization';
import AuthorizationContext from './components/AuthorizationHandler';
import * as config from "./Config";
import SimCardListPage from './pages/SimCards/SimCardListPage';
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
   

const oidcConfig: AuthProviderProps = {
    authority: config.authority,
    client_id: config.client_id,
    redirect_uri: window.location.origin + "/",
    response_type: "code",
    scope: "offline_access openid",
    automaticSilentRenew: true,
    silent_redirect_uri: config.authority,
    post_logout_redirect_uri: window.location.origin + "/",
    silentRequestTimeoutInSeconds: 30,
    validateSubOnSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    revokeTokensOnSignout: false,
    onSigninCallback: () => {
        console.log("onSigninCallback - Original");
    }
};

root.render(
    <React.StrictMode>
        <Authorization oidcConfig={oidcConfig}>
            <AuthorizationContext>
                <BrowserRouter>
                    <Routes>
                        <Route path="" element={<App />}>
                            <Route path='/' element={<Navigate to="/Locations" />} />
                            <Route path='/Locations' element={<LocationListPage />} />
                            <Route path='/ChargePoints' element={<ChargePointListPage />} />
                            <Route path='/ServiceProviders' element={<ServiceProviderListPage />} />
                            <Route path='/LoadGroups' element={<LoadGroupListPage />} />
                            <Route path='/Transactions' element={<TransactionListPage />} />
                            <Route path='/Messages' element={<MessageListPage />} />
                            <Route path='/SIMCards' element={<SimCardListPage />} />
                            <Route
                                path="*"
                                element={
                                    <main style={{ padding: "1rem" }}>
                                        <p>Page not found</p>
                                    </main>
                                } />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthorizationContext>
        </Authorization>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
