import { baseAddress } from "../Config";
import { ChargePoint } from "../Model/ChargePoint";
import { mapper } from "./mapper";

export async function fetchChargePoints(): Promise<ChargePoint[]> {
    let result: ChargePoint[] = [];
    try {
        const resp = await fetch(baseAddress + '/chargepoints',
            {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

        let jsonResponse = await resp.json();

        result = jsonResponse.map((x: object) => mapper<ChargePoint[], object>(x));
    }
    catch (e) {
        console.error(e);
    }
    return result;
}

export async function fetchChargePoint(id: string) {
    try {
        const resp = await fetch(baseAddress + '/chargepoints/' + id,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        return resp.json()
    }
    catch (e) {
        console.error(e);
    }
}

export async function deleteChargePoint(id: string) {
    try {
        const resp = await fetch(baseAddress + `/chargepoints/${id}`,
            {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });
        return resp
    }
    catch (e) {
        console.error(e);
    }
}

export async function postChargePoint(reqBody: Object) {
    try {
        const resp = await fetch(baseAddress + '/chargepoints',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reqBody)
            });
        return resp
    }
    catch (e) {
        console.error(e);
    }
}

export async function patchChargePoint(id: string, reqBody: Object) {
    try {
        const resp = await fetch(baseAddress + '/chargepoints/' + id,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(reqBody)
            });
        return resp
    }
    catch (e) {
        console.error(e);
    }
}