import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchServiceProvider, patchServiceProvider } from '../../../services/ServiceProviderService';
import CheckboxList from '../../../components/CheckboxList';
import { UpdateProps } from '../../../react-app-env';

export default function ServiceProviderUpdate({ closeSideSheet, setRefresh, entity, refresh }: UpdateProps) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<{ name: string; description: string; endpoint: string }>();
  const [OG, setOG] = useState<any>([]);
  const [Capabilities, setCapabilities] = useState<any>([]);
  const [Enabled, setEnabled] = useState<any>([])
  const [errors, setErrors] = useState<{ nameError?: string; unauthorizedError?: string }>({});

  useEffect(() => {
    const resetVars = async () => {
      const Selected = await fetchServiceProvider(entity)
      setSelected(Selected)
      setOG(Selected)
      setCapabilities(Selected.capabilities)
      setEnabled(Selected.capabilities.filter((a: { enabled: any; }) => a.enabled))
      setLoading(false)
    }
    resetVars()
  }, [entity]);

  const handleCancel = () => {
    closeSideSheet();
  }
  const validateForm = () => {
    let e = { ...errors };
    let valid = false;
    if (selected && selected.name && selected.name.length < 3) {
      e.nameError = "Please enter a name with at least 3 characters.";
      valid=false;
    } else if (typeof selected?.name =='string' && selected.name.length < 3){
      e.nameError = "Please enter a name with at least 3 characters.";
      valid=false;
    } else {
      e.nameError = undefined
      valid= true;
    }
    setErrors(e);
    return valid;
  }
  const UpdateField = (requestBody: Object[], current: string, updated: string | undefined, path: string) => {
    if (JSON.stringify(current) !== JSON.stringify(updated)) {
      requestBody.push
        ({
          "op": "replace",
          "path": path,
          "value": updated
        });
    }
  }

  const handleClick = async () => {
    if (validateForm()) {
      const reqBody: Object[] = [];
      UpdateField(reqBody, OG.name, selected?.name, '/name');
      UpdateField(reqBody, OG.description, selected?.description, '/description');
      UpdateField(reqBody, OG.endpoint, selected?.endpoint, '/endpoint');
      if (Enabled !== OG.capabilities?.filter((a: { enabled: boolean; }) => a.enabled)) {
        if (Enabled.length === 0 && OG.capabilities?.filter((a: { enabled: boolean; }) => a.enabled).length > 0) {
          reqBody.push({
            "op": "replace",
            "path": "/capabilities"
          })
          setEnabled([])
        } else
          reqBody.push({
            "op": "replace",
            "path": "/capabilities",
            "value": Capabilities.map((a: { id: any; }) => {
              return {
                "id": a.id,
                "enabled": Enabled.indexOf(a) > -1 ? true : false
              }
            })
          })
      }
      const response = await patchServiceProvider(entity, reqBody)
      if (response?.ok) {
        setRefresh(!refresh);
        closeSideSheet();
      } else {
        if (response?.status === 401 || response?.status === 403) {
          errors.unauthorizedError = "Du har ikke rettighed til at rette i tags på en entity";
        }
        else {
          errors.unauthorizedError = undefined;
          if (response?.status !== 200) {
            errors.nameError = "Der skete en fejl ved opdatering af tags med fejl kode: '";
          }
        }
      }
    }
  }

  if (isLoading) {
    return <Typography
      sx={{
        mt: 2,
        color: "#030F4F",
        fontSize: 25
      }}
    >
      Loading...
    </Typography>
  }

  return (
    <>
      <Box flex={1} >
      <Box>
        <Typography
          sx={{
            mt: 2,
            color: "#030F4F",
            fontSize: 25
          }}
        >
          Edit Service Provider
        </Typography>
        <Typography sx={{ mt: 1, color: "#030F4F" }}>
          Guid: {entity}
        </Typography>
        </Box>
        <Box display='flex' flexDirection="column">
          <TextField
            required
            helperText={errors.nameError}
            error={typeof errors.nameError !== 'undefined' || selected?.name == undefined}
            variant="standard"
            size="small"
            onChange={(e) => setSelected(selected =>
            ({
              ...selected, "name": e.target.value
            } as any)
            )}
            value={selected?.name ?? ""}
            id="outlined-name-service-provider"
            label="Name"
            placeholder={"Enter name here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <TextField
            variant="standard"
            onChange={(e) => setSelected(selected =>
            ({
              ...selected, "description": e.target.value
            } as any)
            )}
            value={selected?.description ?? ""}
            multiline
            maxRows={2}
            id="outlined-service-provider-description"
            label="Description"
            placeholder={"Enter description here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            size="small"
            variant="standard"
            value={selected?.endpoint ?? ""}
            onChange={(e) => setSelected(selected =>
            ({
              ...selected, "endpoint": e.target.value
            } as any)
            )}
            label="Endpoint (ws or wss)"
            placeholder={"Enter endpoint here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <Box flex={1}>
            <InputLabel sx={{ mt: 2 }}>
              Capabilities
            </InputLabel>
            <CheckboxList items={Capabilities} checkedItems={Enabled} setChecked={setEnabled} />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ gap: 1, mt: 8, mb: 9 }}
          flexWrap="nowrap"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>

    </>
  );
}