import React from 'react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Navbar from "./components/Navbar";
import theme from './theme';
import './App.css';
import { Box, Container, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from "styled-components";
import { AuthProviderProps } from 'react-oidc-context';
import Authorization from 'prolonreactcomponents/dist/Authorization/Authorization';
import TabNav from './components/TabNav';
import { Outlet } from 'react-router-dom';
import NavigationSideMenu from './components/NavigationSideMenu';

LicenseInfo.setLicenseKey(
    '2043390db5af3f150059b309a31cc988T1JERVI6MzM4OTMsRVhQSVJZPTE2NzA0MTI1MTYwMDAsS0VZVkVSU0lPTj0x',
);
/*
const oidcConfig: AuthProviderProps = {
  authority: "https://bmslogin.cas.dtu.dk/",
  client_id: "chargepointhubweb",
  //https://www.chargepointhub.dk/
  redirect_uri: "http://localhost:3000",
  response_type: "code",
  scope: "offline_access openid",
  automaticSilentRenew: true,
  silent_redirect_uri: "http://localhost:3000",
  post_logout_redirect_uri: "http://localhost:3000",
  silentRequestTimeoutInSeconds: 30,
  validateSubOnSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeTokensOnSignout: true,
  onSigninCallback: () => {
    console.log("onSigninCallback - Original");
  }
};
*/

export default function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                    <Navbar />
                    <Box
                        flex={1}
                        sx={{
                            position: 'absolute',
                            top: 35,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            display: 'flex'
                        }}
                >
                        <NavigationSideMenu />
                        <Container maxWidth={false} sx={{ mt: 1.5 }} disableGutters>
                            <Outlet />
                        </Container>
                    </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}