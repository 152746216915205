import { Refresh } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColumnGroupHeaderParams, GridColumnGroupingModel, GridColumns, GridRowProps, GridValueGetterParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import ActionBarButton from "../../components/ActionBarButton";
import { fetchLoadGroup } from "../../services/LoadGroupService";
import { styled } from '@mui/material/styles';
import { ActionButton } from "prolonreactcomponents";

export default function LoadGroupDetailPane({ row: rowProp }: { row: any }) {
    const apiRef = useGridApiContext();

    const [rows, setRows] = useState<any>([]);


    async function getLoadGroup(id: string) {
        let loadGroup = await fetchLoadGroup(id);

        let rowList = loadGroup?.connectors?.map((x: any, i: number) => {
            let result = x;

            result.measured_timestamp = result.measuredCurrent?.timestamp ?? "-";

            if (result.measurements && result.measurements.length > 0)
            {
                result.measured_current_l1 = result.measurements.find((x: any) => x.measureand === "Current.Import" && x.phase === "L1");
                result.measured_current_l2 = result.measurements.find((x: any) => x.measureand === "Current.Import" && x.phase === "L2");
                result.measured_current_l3 = result.measurements.find((x: any) => x.measureand === "Current.Import" && x.phase === "L3");
                result.PowerActiveImport_l1 = result.measurements.find((x: any) => x.measureand === "Power.Active.Import" && (x.phase === "L1" || x.phase === null && x.unit === "W"));
                result.PowerActiveImport_l2 = result.measurements.find((x: any) => x.measureand === "Power.Active.Import" && x.phase === "L2");
                result.PowerActiveImport_l3 = result.measurements.find((x: any) => x.measureand === "Power.Active.Import" && x.phase === "L3");
            }

           

            return { ...result, id: i };
        }) ?? [];
        setRows(rowList);
    }

    useEffect(() => {

        if (rowProp.id) {
            getLoadGroup(rowProp.id);
        }
    }, [rowProp.id]);

    // Identifiet - Conector - Status TimeStamp(H) - Status - Info(H) - ErrorCode - Allocated Current - Measured Current - Measured Voltage - Power

    const getValueWithUnit = (params: GridValueGetterParams) => {
        let result = params?.value?.value ?? "--";
        if (result !== "--") {
            result += " " + params?.value?.unit
        }
        return result;
    }

    let phaseWidth = 80;
    const columns: GridColumns = [
        {
            field: 'chargePointId',
            headerName: 'Identifier',
            type: 'string',
            width: 175
        },
        {
            field: 'connectorId',
            headerName: 'Con.',
            type: 'string',
            width: 50
        },
        {
            field: 'statusTimestamp',
            headerName: 'Status Timestamp',
            hide: true,
            type: 'dateTime',
            width: 200,
            valueGetter: (params: GridValueGetterParams) => {
                return (params.value == null ? "---- -- -- --:--:--" : format(parseISO(params.value), "yyyy-MM-dd HH:mm:ss.T"));
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            width: 120
        },
        {
            field: 'info',
            headerName: 'Info',
            type: 'string',
            width: 200,
            hide: true
        },
        {
            field: 'errorCode',
            headerName: 'Error',
            type: 'string',
            width: 100
        },
        {
            field: 'allocated_current_l1',
            headerName: 'L1',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'allocated_current_l2',
            headerName: 'L2',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'allocated_current_l3',
            headerName: 'L3',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'measured_current_l1',
            headerName: 'L1',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'measured_current_l2',
            headerName: 'L2',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'measured_current_l3',
            headerName: 'L3',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'measured_voltage_l1',
            headerName: 'L1',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'measured_voltage_l2',
            headerName: 'L2',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'measured_voltage_l3',
            headerName: 'L3',
            type: 'number',
            width: phaseWidth,
            valueGetter: getValueWithUnit
        },
        {
            field: 'PowerActiveImport_l1',
            headerName: 'L1',
            type: 'number',
            width: 80,
            valueGetter: getValueWithUnit
        },
        {
            field: 'PowerActiveImport_l2',
            headerName: 'L2',
            type: 'number',
            width: 80,
            valueGetter: getValueWithUnit
        },
        {
            field: 'PowerActiveImport_l3',
            headerName: 'L3',
            type: 'number',
            width: 80,
            valueGetter: getValueWithUnit
        }
    ];

    const columnGroupingModel: GridColumnGroupingModel =
        [
            {
                groupId: "AllocatedCurrent",
                headerName: "Allocated Current",
                children:
                    [
                        { field: "allocated_current_l1" },
                        { field: "allocated_current_l2" },
                        { field: "allocated_current_l3" }
                    ]
            },
            {
                groupId: "MeasuredCurrent",
                headerName: "Measured Current",
                children:
                    [
                        { field: "measured_current_l1" },
                        { field: "measured_current_l2" },
                        { field: "measured_current_l3" }
                    ]
            },
            {
                groupId: "MeasuredVoltage",
                headerName: "Measured Voltage",
                children:
                    [
                        { field: "measured_voltage_l1" },
                        { field: "measured_voltage_l2" },
                        { field: "measured_voltage_l3" }
                    ]
            },
            {
                groupId: "PowerActiveImport",
                headerName: "Power",
                children:
                    [
                        { field: "PowerActiveImport_l1" },
                        { field: "PowerActiveImport_l2" },
                        { field: "PowerActiveImport_l3" }
                    ]
            }
        ]


    return (
        <Stack sx={{ py: 2, boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <ActionButton
                    text="Refresh"
                    action={() => {
                        getLoadGroup(rowProp.id);
                    }}
                    icon={<Refresh />}
                />
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <DataGridPro
                        experimentalFeatures={{ columnGrouping: true }}
                        density="compact"
                        autoHeight
                        columns={columns}
                        rows={rows}
                        sx={{ flex: 1 }}
                        hideFooter
                        columnGroupingModel={columnGroupingModel}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}