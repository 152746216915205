import { Box } from '@mui/material';
import { useState } from 'react';
import * as React from 'react';
import ChargePointListView from './ChargePointListView';

export default function ChargePointListPage() {
  const [sideSheet, setSideSheet] = useState<React.ReactNode |null>(null);
  const [searchValue, setSearchValue] = useState([])

  return (
    <Box display='flex' flex={1} sx={{ height: "100%" }}>
      <Box display='flex' flex={1} flexDirection="column">
        <ChargePointListView setSideSheet={setSideSheet}/>
      </Box>
      {sideSheet}
    </Box>
  );
}
