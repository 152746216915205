import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EastIcon from '@mui/icons-material/East';
import ErrorIcon from '@mui/icons-material/Error';
import EvStationIcon from '@mui/icons-material/EvStation';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RefreshIcon from '@mui/icons-material/Refresh';
import WestIcon from '@mui/icons-material/West';
import { Autocomplete, Box, Button, CircularProgress, Input, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { DataGridPro, GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import { ActionBar, ActionButton } from 'prolonreactcomponents';
import React, { ChangeEvent, ChangeEventHandler, HtmlHTMLAttributes, useEffect, useRef, useState } from 'react';
import { ChargePoint } from '../../Model/ChargePoint';
import { fetchChargePoints } from '../../services/ChargePointService';
import { fetchMessages } from '../../services/MessageService';
import { fetchServiceProviders } from '../../services/ServiceProviderService';
import { ToolBar, ToolbarProps } from './Toolbar';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 1200,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        whiteSpace: "pre-wrap"
    },
}));

type CustomNoRowsOverlayProps = {
    loading:boolean
}


function CustomNoRowsOverlay({ loading }: CustomNoRowsOverlayProps)
{
    console.log("Loading=" + loading)

    return (
        <StyledGridOverlay>
            {
                loading ? <CircularProgress /> : 
            <>
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1 }}>No Messages Found</Box>
            </>
        }
        </StyledGridOverlay>
    );
}

export default function MessageListview() {

    const columns: GridColDef[] = [
        {
            field: 'path',
            headerName: 'Path',
            type: 'singleSelect',
            width: 100,
            renderCell: (params: any) => {
                switch (params.value) {
                    case 0:
                        return <div>
                            <EvStationIcon sx={{ fontSize: 16 }}></EvStationIcon>
                            <EastIcon sx={{ fontSize: 16 }}></EastIcon>
                            <DeviceHubIcon sx={{ fontSize: 16 }}></DeviceHubIcon>
                            <EastIcon sx={{ fontSize: 16, color: '#FFFFFF' }}></EastIcon>
                            <ManageAccountsIcon sx={{ fontSize: 16 }}></ManageAccountsIcon>
                        </div>
                    case 1:
                        return <div>
                            <EvStationIcon sx={{ fontSize: 16 }}></EvStationIcon>
                            <WestIcon sx={{ fontSize: 16 }}></WestIcon>
                            <DeviceHubIcon sx={{ fontSize: 16 }}></DeviceHubIcon>
                            <WestIcon sx={{ fontSize: 16, color: '#FFFFFF' }}></WestIcon>
                            <ManageAccountsIcon sx={{ fontSize: 16 }}></ManageAccountsIcon>
                        </div>
                    case 2:
                        return <div>
                            <EvStationIcon sx={{ fontSize: 16 }}></EvStationIcon>
                            <EastIcon sx={{ fontSize: 16, color: '#FFFFFF' }}></EastIcon>
                            <DeviceHubIcon sx={{ fontSize: 16 }}></DeviceHubIcon>
                            <EastIcon sx={{ fontSize: 16 }}></EastIcon>
                            <ManageAccountsIcon sx={{ fontSize: 16 }}></ManageAccountsIcon>
                        </div>
                    case 3:
                        return <div>
                            <EvStationIcon sx={{ fontSize: 16 }}></EvStationIcon>
                            <WestIcon sx={{ fontSize: 16, color: '#FFFFFF' }}></WestIcon>
                            <DeviceHubIcon sx={{ fontSize: 16 }}></DeviceHubIcon>
                            <WestIcon sx={{ fontSize: 16 }}></WestIcon>
                            <ManageAccountsIcon sx={{ fontSize: 16 }}></ManageAccountsIcon>
                        </div>
                    default:
                        return <ErrorIcon></ErrorIcon>
                }
            }
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            type: 'dateTime',
            width: 160,
            valueGetter: (params: GridValueGetterParams) => {
                return (params.value == null ? "No further time info" : format(parseISO(params.value), "yyyy-MM-dd HH:mm:ss"));
            }
        },
        {
            field: 'chargePointId',
            headerName: 'Charge Point Id',
            type: 'string',
            width: 175,
        },
        {
            field: 'chargePointName',
            headerName: 'Charge Point',
            type: 'string',
            width: 175,
        },
        {
            field: 'serviceProviderId',
            headerName: 'Service Provider Id',
            type: 'string',
            width: 175,
        },
        {
            field: 'serviceProviderName',
            headerName: 'Service Provider',
            type: 'string',
            width: 130,
        },
        {
            field: 'messageTypeId',
            headerName: 'Type',
            type: 'string',
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => {
                switch (params.value) {
                    case 2:
                        return 'Call';
                    case 3:
                        return 'Response';
                    case 4:
                        return 'Error';
                    default:
                        return 'No message type';
                }
            }
        },
        {
            field: 'uniqueId',
            headerName: 'Id',
            type: 'string',
            width: 100
        },
        {
            field: 'action',
            headerName: 'Action',
            type: 'string',
            width: 100
        },
        {
            field: 'payload',
            headerName: 'Payload',
            type: 'string',
            width: 350,
            renderCell: (params: GridRenderCellParams<string>) => {
                let jsonString = params.value ?? "";
                let jsonPretty = JSON.stringify(JSON.parse(jsonString), null, 10);
                console.log(jsonPretty);
                return (
                    <HtmlTooltip title={jsonPretty} describeChild>
                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{jsonPretty}</div>
                    </HtmlTooltip>
                )
            }
        }

    ];


    const [loading, setLoading] = useState<boolean>(true);
    const [messages, setMessages] = useState([]);
    // const [ChargePoints, setChargePoints] = useState([]);
    const [ServiceProviders, setServiceProviders] = useState([]);
    const [chargePoints, setChargePoints] = useState<any[]>([]);
    const [period, setPeriod] = useState<DateRange<Date>>([new Date(), null]);
    // const [ChargePointId, setChargePointId] = useState([]);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<any>();
    const [selectedChargePoint, setSelectedChargePoint] = useState<any>();
    const [refresh, setRefresh] = useState(false)
    const [action, setAction] = useState<string>();
    const controllerRef = useRef();


    const getServiceProviders = async () => {
        const sp = await fetchServiceProviders()
        setServiceProviders(sp)
    }

    const getChargePoints = async () => {
        let cp: ChargePoint[] = await fetchChargePoints();
        if (selectedServiceProvider) {
            cp = cp.filter(x => x.serviceProviders.some(y => y.id === selectedServiceProvider.id));
        }
        setChargePoints(cp);
    }

    const getMessages = async () => {
        const msg = await fetchMessages(period, selectedServiceProvider?.id, selectedChargePoint?.id, action, controllerRef);
        if (msg)
        {
            setMessages(msg);
            setLoading(false);
        }
    }

    useEffect(() => {
        getServiceProviders();
        getChargePoints();
    }, [])

    useEffect(() => {
        getChargePoints();
    }, [selectedServiceProvider]);

    useEffect(() =>
    {
        setLoading(true);
        getMessages()
    }, [period, selectedServiceProvider, selectedChargePoint,action, refresh])

    const toolBarProps: ToolbarProps = {
        action: action,
        setAction: setAction,
        chargePoints: chargePoints,
        setSelectedChargePoint: setSelectedChargePoint,
        selectedChargePoint: selectedChargePoint,
        ServiceProviders: ServiceProviders,
        setSelectedServiceProvider: setSelectedServiceProvider,
        selectedServiceProvider: selectedServiceProvider,
        period: period,
        setPeriod: setPeriod,
        getMessages: getMessages
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <Box style={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                <Typography variant='h6' sx={{
                    pl: 2,
                    py: 1
                }} >
                    Messages
                </Typography>
                <DataGridPro
                    disableSelectionOnClick
                    rows={messages.map((x: any, i: number) => {
                        x.id = i;
                        return x;
                    })}
                    columns={columns}
                    density='compact'
                    autoPageSize
                    pagination
                    rowHeight={30}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        Toolbar: ToolBar
                    }}
                    componentsProps={{
                        toolbar: toolBarProps,
                        noRowsOverlay: {
                            loading: loading
                        }
                    } }   
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide id on default but allow user to view
                                "chargePointId": false,
                                "serviceProviderId": false,
                            },
                        },
                    }}
                    sx={{
                        border: 0,
                        flexGrow: 1,
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            // backgroundColor: "transparent",
                            "& .MuiDataGrid-cell": {
                                padding: 0
                            }
                        },
                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "whitesmoke"
                            },
                            "&:first-of-type": {
                                borderTop: "1px solid rgba(224, 224, 224, 1)"
                            }
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none"
                        }
                    }}
                />
            </Box>
        </Box>
    )
}