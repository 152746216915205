import { baseAddress } from "../Config";

export async function fetchTransactions() {
  try {
    const resp = await fetch(baseAddress + '/transactions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const data = await resp.json()
    return data
  }
  catch (e) {
    console.error(e);
  }
}

export async function postTransaction(reqBody : Object) {
  try {
    const resp = await fetch(baseAddress + '/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const data = await resp.json()
    return data
  }
  catch (e) {
    console.error(e);
  }
}