
import RefreshIcon from '@mui/icons-material/Refresh';
import { Autocomplete, Box, Button, createFilterOptions, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ActionBar,  } from 'prolonreactcomponents';
import React, { ChangeEvent} from 'react';

export type ToolbarProps = {
    action: string | undefined,
    setAction: any,
    chargePoints: any[],
    selectedChargePoint: any,
    setSelectedChargePoint: any,
    ServiceProviders: any[],
    setSelectedServiceProvider: any,
    selectedServiceProvider: any,
    period: any,
    setPeriod: any,
    getMessages: any
}

const actions = [
    "Authorize",
    "BootNotification",
    "ChangeAvailability",
    "ChangeConfiguration",
    "ClearCache",
    "DataTransfer",
    "GetConfiguration",
    "Heartbeat",
    "MeterValues",
    "RemoteStartTransaction",
    "RemoteStopTransaction",
    "Reset",
    "StartTransaction",
    "StatusNotification",
    "StopTransaction",
    "UnlockConnector",
    "GetDiagnostics",
    "DiagnosticsStatusNotification",
    "FirmwareStatusNotification",
    "UpdateFirmware",
    "GetLocalListVersion",
    "SendLocalList",
    "CancelReservation",
    "ReserveNow",
    "ClearChargingProfile",
    "GetCompositeSchedule",
    "SetChargingProfile",
    "TriggerMessage"


]

export function ToolBar({
    action,
    setAction,
    chargePoints,
    setSelectedChargePoint,
    selectedChargePoint,
    ServiceProviders,
    setSelectedServiceProvider,
    selectedServiceProvider,
    period,
    setPeriod,
    getMessages}: ToolbarProps)
{
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: any) =>
        {
            return option['id'] + option['name'];
        },
    });

    const chargePointFilterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: any) =>
        {
            return option['chargePointId'];
        },
    });
    const actionFilterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: any) =>
        {
            return option['chargePointId'];
        },
    });
    return (
        <Box key="messageToolbar">
            <ActionBar paddingBottom={0} marginLeft={5} key="actionBar">
                <Autocomplete
                    size="small"
                    filterOptions={filterOptions}
                    value={selectedServiceProvider}
                    options={ServiceProviders}
                    getOptionLabel={(option) => { return option.name.toString() }}
                    onChange={(event, newValue) =>
                    {
                        // console.log(newValue)
                        setSelectedServiceProvider(newValue)
                    }}
                    noOptionsText={"No service providers found"}
                    renderInput={(params) =>
                    {
                        return (
                            <TextField {...params} variant="standard" placeholder="Search by Id or Name" label="Service Provider" InputProps={{ ...params.InputProps }} />
                        );
                    }}
                    sx={{ mt: -1, ml: 2, width: 200 }}
                />
                <Autocomplete
                    size="small"
                    value={selectedChargePoint}
                    filterOptions={chargePointFilterOptions}
                    options={chargePoints}
                    getOptionLabel={(option) => { return option.chargePointId }}
                    onChange={(event, newValue) =>
                    {
                        // console.log(newValue)
                        setSelectedChargePoint(newValue);
                    }}
                    noOptionsText={"No charge point found"}
                    renderInput={(params) =>
                    {
                        return (
                            <TextField {...params} variant="standard" placeholder="Search by Id or Name" label="Chargepoint" InputProps={{ ...params.InputProps }} />
                        );
                    }}
                    sx={{ mt: -1, ml: 2, width: 200 }}
                />

                <Autocomplete
                    size="small"
                    value={action ?? ""}
                    filterOptions={actionFilterOptions}
                    options={actions}
                    getOptionLabel={(option) => { return option }}
                    onChange={(event, newValue) =>
                    {
                        setAction(newValue);
                    }}
                    noOptionsText={"No actions found"}
                    renderInput={(params) =>
                    {
                        return (
                            <TextField {...params} variant="standard" placeholder="Search by action" label="Action" InputProps={{ ...params.InputProps }} />
                        );
                    }}
                    sx={{ mt: -1, ml: 2, width: 200 }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: '', end: '' }} >
                    <DateRangePicker
                        calendars={2}
                        value={period}
                        onChange={(newValue) => setPeriod(newValue)}
                        mask={"____-__-__"}
                        inputFormat="yyyy-MM-dd"
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <Box sx={{ mx: 2 }}> From </Box>
                                <TextField {...startProps} size="small" />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} size="small" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>

                <Button
                    startIcon={<RefreshIcon />}
                    onClick={() =>
                    {
                        getMessages();
                    }}
                >
                </Button>
            </ActionBar>
        </Box>
    )
}