import React from 'react'
import { UpdateProps } from '../../../react-app-env'
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { selectedGridRowsCountSelector } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { fetchLocation, patchLocation } from '../../../services/LocationService';

export default function LocationUpdate({ closeSideSheet, setRefresh, entity, refresh }: UpdateProps) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>()
  const [street, setStreet] = useState<string>();
  const [city, setCity] = useState<string>();
  const [postalCode, setPostalCode] = useState<any>();
  const [latitude, setLatitude] = useState<string>();
  const [longitude, setLongitude] = useState<string>();
  const [OG, setOG] = useState<any>();
  const [errors, setErrors] = useState<{ nameError?: string; deleteError?: string; postalCodeError?: string; unauthorizedError?: string }>({});

  const handleCancel = () => {
    closeSideSheet();
  }
  const UpdateField = (requestBody: any[], current: any, updated: string | number | undefined, path: string) => {
    if (JSON.stringify(current) !== JSON.stringify(updated)) {
      requestBody.push
        ({
          "op": "replace",
          "path": path,
          "value": updated
        });
    }
  }

  const handleClick = async () => {
    const reqBody: Object[] = [];
    if (validateForm()) {
      UpdateField(reqBody, OG.name, name, '/name');
      UpdateField(reqBody, OG.street, street, '/street');
      UpdateField(reqBody, OG.city, city, '/city');
      UpdateField(reqBody, OG.postalCode, postalCode, '/postalcode');
      if (latitude !== OG.coord?.latitude || longitude !== OG.coord?.longitude) {
        reqBody.push({
          "op": "replace",
          "path": "/coord",
          "value": {
            "latitude": latitude,
            "longitude": longitude
          }
        })
      }
      const response = await patchLocation(entity, reqBody)
      if (response?.status === 200) {
        setRefresh(!refresh);
        closeSideSheet();
      } else {
        if (response?.status === 401 || response?.status === 403) {
          errors.unauthorizedError = "Du har ikke rettighed til at rette i tags på en entity";
        }
        else {
          errors.unauthorizedError = undefined;
          if (response?.status !== 200) {
            errors.nameError = "Der skete en fejl ved opdatering af tags med fejl kode: '";
          }
        }
      }
    }
  }

  const validateForm = () => {
    let e = { ...errors };
    let valid = false;
    if (typeof name !== 'undefined' && name.length < 3) {
      e.nameError = "Please enter a name with at least 3 characters.";
      valid = false;
    } else {
      e.nameError = undefined;
      valid = true;
    }
    if (postalCode !== "") {
      if (postalCode.length === 4 && /^\d+$/.test(postalCode)) {
        e.postalCodeError = undefined;
        valid = true;
      }
      else {
        valid = false;
        e.postalCodeError = "Please enter a valid postal code.";
      }
    }
    setErrors(e);
    return valid;
  }

  useEffect(() => {
    const getLocation = async () => {
      const location = await fetchLocation(entity)
      setOG(location)
      setName(location.name);
      setStreet(location.street);
      setCity(location.city);
      setPostalCode(location.postalCode);
      setLatitude(location.coord.latitude)
      setLongitude(location.coord.longitude)
      setLoading(false)
    }
    getLocation()
  }, [entity]);

  if (isLoading) {
    return <Typography
      sx={{
        mt: 2,
        color: "#030F4F",
        fontSize: 25
      }}
    >
      Loading...
    </Typography>
  }
  return (
    <>
      <Box flex={1} overflow={'auto'} >
        <Typography
          sx={{
            mt: 2,
            color: "#030F4F",
            fontSize: 25
          }}
        >
          Edit Location
        </Typography>
        <Typography
          sx={{
            mt: 1,
            color: "#030F4F",
          }}
        >
          Guid: {entity}
        </Typography>
        <Box display='flex' flexDirection="column">
          <TextField
            required
            error={typeof errors.nameError !== 'undefined'}
            variant="standard"
            size="small"
            onChange={(e) => { setName(e.target.value) }}
            value={name}
            label="Name"
            placeholder={"Enter name here"}
            sx={{ mt: 2, width: '16vw' }}
            helperText={errors.nameError}
          />
          <TextField
            variant="standard"
            size="small"
            onChange={(e) => { setStreet(e.target.value) }}
            value={street}
            label="Street"
            placeholder={"Enter street here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <TextField
            type="number"
            error={typeof errors.postalCodeError !== 'undefined' && postalCode.length !== 4}
            variant="standard"
            size="small"
            onChange={(e) => { setPostalCode(e.target.value) }}
            value={postalCode}
            label="Postal Code"
            placeholder={"Enter postal code here"}
            sx={{ mt: 2, width: '16vw' }}
            helperText={errors.postalCodeError}
          />
          <TextField
            variant="standard"
            size="small"
            onChange={(e) => { setCity(e.target.value) }}
            value={city}
            label="City"
            placeholder={"Enter city here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                type="number"
                variant="standard"
                size="small"
                onChange={(e) => { setLatitude(e.target.value) }}
                value={latitude}
                id="outlined-location-postal-code"
                label="Latitude"
                placeholder={"Enter latitude here"}
                sx={{ mt: 2, width: '5vw' }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="number"
                variant="standard"
                size="small"
                onChange={(e) => { setLongitude(e.target.value) }}
                value={longitude}
                id="outlined-location-postal-code"
                label="Longitude"
                placeholder={"Enter longitude here"}
                sx={{ mt: 2, width: '5vw' }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ gap: 1, mt: 6, mb: 9 }}
          flexWrap="nowrap"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              textTransform: 'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{
              textTransform: 'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}