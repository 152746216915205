import React from 'react'
import { AppBar, Box, Button, Link, Toolbar, Typography, Grid } from '@mui/material';
import { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from 'react-oidc-context';

export default function Navbar() {
    const [textFieldEndValue] = useState();
    const auth = useAuth();
    return (
        <Box>
            <AppBar position="fixed" sx={{ minHeight: 40, maxHeight: 40, background: "#b00000", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar variant="dense" sx={{ minHeight: 40, maxHeight: 40 }}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Typography variant="h6" >
                            <Link href='/Locations' underline="none" sx={{
                                color: "#FFFFFF"
                            }}>
                                Charge Point Hub
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Typography variant="body2" sx={{ display: 'inline', m: 2, mr: -3 }}>{"Hi " + auth.user?.profile?.sub}</Typography>
                        <Button
                            sx={{
                                textTransform: "capitalize",
                                color: "white",
                            }}
                            endIcon={<AccountCircleIcon />}
                            
                        ></Button>
                        <Button sx={{ color: "#FFFFFF" }} onClick={() => { auth.signoutRedirect() }}> Log out </Button>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar variant="dense" sx={{ minHeight: 32, maxHeight: 32 }} />
        </Box>
    )
}