import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { CreateProps } from '../../../react-app-env';
import { postServiceProvider } from '../../../services/ServiceProviderService';


export default function ServiceProviderCreate({closeSideSheet, setRefresh, refresh}: CreateProps) {
  const [name, setName] = useState("");
  const [desc, setDescription] = useState("");
  const [endpoint, setEndpoint] = useState("")
  const [Capabilities, setCapabilities] = useState([]);
  const [selectedServerCapabilities, setSelectedServerCapabilities] = useState([])
  const [errors, setErrors] = useState<{nameError? : string; savingError?: string; unauthorizedError?:string}>({});

  const validateNameChange = () => {
    let e = { ...errors };
    let valid= false;
    if (name.length < 3) {
      e.nameError = "Please enter a name with at least 3 characters.";
      valid = false;
    } else {
      e.nameError = undefined;
      valid= true;
    }
    setErrors(e);
    return valid;
  }

  const handleCancel = () => {
    closeSideSheet();
  }

  const handleClick = async () => {
    
    if (validateNameChange()) {
      const reqBody = {
        "name": name,
        "description": desc,
        "endpoint": endpoint,
        "capabilities": selectedServerCapabilities ?? null
      };
      // POST request
      const response = await postServiceProvider(reqBody)
      if (response?.ok) {
        setRefresh(!refresh);
        closeSideSheet();
      } else {
        let e = { ...errors };
        e.savingError = "There was an error saving this service provider. Try to submit again";
        setErrors(e);
      }
    }
  }

  return (
    <>
      <Box flex={1} overflow={'auto'}>
        <Typography
          sx={{
            mt: 2,
            color: "#030F4F",
            fontSize: 25
          }}
        >
          Add New Service Provider
        </Typography>
        <Box display='flex' flexDirection="column">
          <TextField
            required
            helperText={errors.nameError}
            error={typeof errors.nameError !== 'undefined' || typeof name == 'undefined'}
            size="small"
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            placeholder={"Enter name here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <TextField
            size="small"
            variant="standard"
            value={desc}
            multiline
            maxRows={2}
            onChange={(e) => { setDescription(e.target.value) }}
            id="outlined-multiline-static"
            label="Description"
            placeholder={"Enter description here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <TextField
            size="small"
            variant="standard"
            value={endpoint}
            onChange={(e) => { setEndpoint(e.target.value) }}
            label="Endpoint (ws or wss)"
            placeholder={"Enter endpoint here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          {/* <FormControl variant="standard" sx={{  mt: 2,width: '16vw' }} >
                    <InputLabel shrink size="small" >Capabilities</InputLabel>
                    <Select
                        multiple
                        displayEmpty
                        size="small"
                        value={selectedServerCapabilities}
                        onChange={handleCapabilitiesChange}
                        renderValue={(selected) =>
                            selected.map((obj) => (Capabilities.filter(e => e.id === obj).map(x=>x.name))).join(", ")
                            
                        }
                        defaultValue={selectedServerCapabilities}
                        >
                        {Capabilities.map((single) => (
                            <MenuItem
                                key={single.id}
                                value = {single.id}
                            >
                                <Checkbox checked={selectedServerCapabilities.indexOf(single.id) > -1} />
                                <ListItemText primary={single.name} />
                            </MenuItem>    
                        ))}
                    </Select>
                    </FormControl> */}
        </Box>



      </Box>
      <Box>

        <Box
          display="flex"
          flexDirection="row"
          sx={{ gap: 1, mt: 6, mb: 9 }}
          flexWrap="nowrap"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}