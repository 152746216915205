import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Alert, Box, Button, Fade, IconButton, Typography } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef, gridDetailPanelExpandedRowsContentCacheSelector, GridRenderCellParams, GridRowParams, GridToolbarQuickFilter, GridValueFormatterParams, GridValueGetterParams, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import ActionBarButton from '../../components/ActionBarButton';
import CustomDivider from '../../components/CustomDivider';
import SideSheet from '../../components/SideSheet';
import DeleteEntity from '../../components/SideSheets/DeleteEntity';
import LoadGroupCreate from './SideSheets/LoadGroupCreate';
import LoadGroupUpdate from './SideSheets/LoadGroupUpdate';
import { fetchLoadGroups } from '../../services/LoadGroupService';
import React from 'react'
import { ListViewProps } from '../../react-app-env';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadGroupDetailPane from './LoadGroupDetailPane';
import { ActionBar, ActionButton } from 'prolonreactcomponents';
import GridLocalSearchField from '../../components/GridLocalSearchField';


export default function LoadGroupListView({ setSideSheet }: ListViewProps) {
    const [selectionModel, setSelectionModel] = useState<any[]>([])
    const [LoadGroups, setLoadGroups] = useState<any[]>([])
    const [toggle, setToggle] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [errors, setErrors] = useState(
        {
            deleteError: null,
            fetchError: null,
        }
    );

    useEffect(() => {
        const getLoadGroups = async () => {
            const loadGroupsfromserver = await fetchLoadGroups()
            setLoadGroups(loadGroupsfromserver)
        }
        getLoadGroups()
    }, [refresh])

    const columns: GridColDef[] = [

        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            width: 175
        },
        {
            field: 'staticMaxLoad',
            headerName: 'Max Load',
            type: 'number',
            width: 100
        },
        {
            field: 'location',
            headerName: 'Location',
            type: 'string',
            width: 175,
            valueGetter: (params: GridValueGetterParams) => params.row.location != null ? params.row.location.name : "No location"
        },
        {
            field: 'chargePoints',
            headerName: 'Charge Points',
            type: 'string',
            width: 350,
            valueGetter: (params: GridValueGetterParams) => (
                params.row.chargePoints.map((obj: any) => {
                    return obj['chargePointId']
                })
            ),
            valueFormatter: (params: GridValueFormatterParams<any>) => params.value.join(", ")
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
                <CustomDetailPanelToggle id={params.id} value={params.value} />
            )

        }
    ];

    const closeSideSheet = () => {
        setSelectionModel([]);
        setSideSheet(null);
    }

    const handleNewLoadGroup = () => {
        setSideSheet(<SideSheet><LoadGroupCreate closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh}></LoadGroupCreate> </SideSheet>)
    }

    const handleEditLoadGroup = (id: string) => {
        setSideSheet(<SideSheet><LoadGroupUpdate entity={id} closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh}></LoadGroupUpdate> </SideSheet>)
    }

    const setDelete = () => {
        let selectedEntities = LoadGroups.filter(x => selectionModel.includes(x.id));
        setSideSheet(<SideSheet><DeleteEntity selected={selectedEntities} closeSideSheet={closeSideSheet} setRefresh={setRefresh} refresh={refresh} type={"loadgroup"}></DeleteEntity> </SideSheet>)
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            return <LoadGroupDetailPane row={row} />
        },
        [],
    );

    const getDetailPanelHeight = React.useCallback<
        NonNullable<DataGridProProps['getDetailPanelHeight']>
    >(() => 'auto' as const, []);

    const ToolBar = () => {
        return (
            <Box>
                <ActionBar paddingBottom={0} marginLeft={5}>
                    <ActionButton text={"Add New Load Group"} action={handleNewLoadGroup} icon={<AddIcon />} />
                    <ActionButton text={"Delete"} action={setDelete} icon={<DeleteOutlineIcon />} disabled={!toggle} />
                    <GridLocalSearchField />
                </ActionBar>
            </Box>
        )
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
            <Box style={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                <Typography variant='h6' sx={{
                    pl: 2,
                    py: 1
                }} >
                    Load Groups
                </Typography>

                <DataGridPro
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={getDetailPanelHeight}
                    disableSelectionOnClick
                    rows={LoadGroups}
                    columns={columns}
                    density='compact'
                    checkboxSelection
                    pagination
                    selectionModel={selectionModel}
                    onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        ids.length > 0 ? setToggle(true) : setToggle(false);
                    }}
                    rowHeight={30}
                    onCellClick={(params, event) => {
                        if (params.colDef.field !== "__detail_panel_toggle__") {
                            handleEditLoadGroup(params.row.id);
                        }
                    }}
                    components={{
                        Toolbar: ToolBar,
                    }}
                    sx={{
                        border: 0,
                        "& .MuiDataGrid-pinnedColumnHeaders": {
                            boxShadow: "none",
                            backgroundColor: "transparent"
                        },
                        "& .MuiDataGrid-pinnedColumns": {
                            boxShadow: "none",
                            // backgroundColor: "transparent",
                            "& .MuiDataGrid-cell": {
                                padding: 0
                            }
                        },
                        "& .MuiDataGrid-row": {
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "whitesmoke"
                            },
                            "&:first-of-type": {
                                borderTop: "1px solid rgba(224, 224, 224, 1)"
                            }
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none"
                        }
                    }}
                />
            </Box>
        </Box>
    )
}


function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling �getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}
