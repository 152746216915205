import { format } from 'date-fns';
import { baseAddress } from "../Config";

export async function fetchMessages(period: any[], serviceProviderId: any, chargePointId: any, action:any, controllerRef?: any) {
    try
    {
        if (controllerRef)
        {
            if (controllerRef.current)
            {
                controllerRef.current.abort();
            }

            const controller = new AbortController();
            controllerRef.current = controller;
        }

        let url = baseAddress + "/Messages?"
        if (period) {
            if (period[0]) {
                url += `From=${format(period[0], "yyyy-MM-dd")}`;
            }
            if (period[1]) {
                url += `&To=${format(period[1], "yyyy-MM-dd")}`;
            }
        }
        // if (ChargePointId?.id ?? null) {
        //   url += `&ChargePointId=${ChargePointId.id}`;
        // }
        if (serviceProviderId) {
            url += `&ServiceProviderId=${serviceProviderId}`;
        }

        if (chargePointId) {
            url += `&ChargePointId=${chargePointId}`;
        }
        if (action)
        {
            url += `&action=${action}`;
        }

        url += `&MaxCount=1000`
        const results = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            signal: controllerRef?.current?.signal
        });
        const data = await results.json()
        return data
    }
    catch (e) {
        console.error(e);
    }
}
