import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Checkbox, FormHelperText, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { fetchChargePoints } from '../../../services/ChargePointService';
import { fetchLocations } from '../../../services/LocationService';
import { CreateProps } from '../../../react-app-env';
import { postLoadGroup } from '../../../services/LoadGroupService';

export default function LoadGroupCreate({ closeSideSheet, setRefresh, refresh }: CreateProps) {
  const [ChargePoints, setChargePoints] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [maxLoad, setMaxLoad] = useState<string>("");
  const [description, setDescription] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [selectedChargers, setSelectedChargers] = useState<any[]>([]);
  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<{ nameError?: string; deleteError?: string; locationError?: string; typeError?: string; unauthorizedError?: string; maxLoadError?: string }>({});
  useEffect(() => {
    const getChargePoints = async () => {
      const chargersfromserver = await fetchChargePoints()
      setChargePoints(chargersfromserver)
    }
    const getLocations = async () => {
      const locationsfromserver = await fetchLocations()
      setLocations(locationsfromserver)
    }
    getChargePoints()
    getLocations()
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedChargers>) => {
    const {
      target: {
        value },
    } = event;
    event.target.value.indexOf("") > -1 ? setSelectedChargers([]) :
      setSelectedChargers(
        typeof value === 'string' ? value.split(',') : value,
      );
  };

  const handleCancel = () => {
    closeSideSheet();
  }

  const handleClick = async () => {
    if (validateLoadGroup()) {
      const reqBody = {
        "name": name,
        "locationId": selectedLocation.id,
        "chargePoints":
          selectedChargers.length > 1 ? selectedChargers : [],
        "description": description,
        "staticmaxload": maxLoad
      }
      // POST request 
      const response = await postLoadGroup(reqBody)
      if (response?.ok) {
        setRefresh(!refresh);
        closeSideSheet();
      } else {
        if (response?.status === 401 || response?.status === 403) {
          errors.unauthorizedError = "Du har ikke rettighed til at rette i tags på en entity";
        }
      }
    }
  }

  const validateLoadGroup = () => {
    let e = { ...errors };
    let valid = false;
    if (parseInt(maxLoad)) {
      e.typeError = undefined
      valid = true;
    } else {
      e.typeError = "Enter an integer value"
      valid = false;
    }
    if (name.length < 3) {
      e.nameError = "Enter a name with at least 3 characters.";
      valid = false;
    } else {
      e.nameError = undefined;
      valid = true;
    }
    if (typeof selectedLocation == 'undefined') {
      e.locationError = "Select a location";
      valid = false;
    } else {
      e.locationError = undefined;
      valid = true;
    }
    setErrors(e);
    return valid;
  }

  return (
    <>
      <Box flex={1} overflow={'auto'}>
        <Typography
          sx={{
            mt: 2,
            color: "#030F4F",
            fontSize: 25
          }}>
          Add New Load Group</Typography>
        <Box flexDirection="column" display='flex'>
          <FormControl error={typeof errors.nameError !== 'undefined' && name.length === 0} variant="standard" sx={{ mt: 2, width: '16vw' }}>
            <InputLabel required>Name</InputLabel>
            <Input
              size="small"
              onChange={(e) => setName(e.target.value)}
              placeholder={"Enter name here"}
              value={name}
              sx={{ mt: 2, width: '16vw' }}
            />
            <FormHelperText error={typeof errors.nameError !== 'undefined'}>
              {errors.nameError}
            </FormHelperText>
          </FormControl>
          <TextField
            variant="standard"
            value={description}
            onChange={(e) => { setDescription(e.target.value) }}
            multiline
            rows={2}
            label="Description"
            placeholder={"Enter description here"}
            sx={{ mt: 2, width: '16vw' }}
          />
          <FormControl error={typeof errors.locationError !== 'undefined' && typeof selectedLocation == 'undefined'} variant="standard" sx={{ mt: 2, width: '16vw' }} >
            <InputLabel required shrink size="small">Location</InputLabel>
            <Select
              displayEmpty
              size="small"
              label="Location"
              value={selectedLocation?.id ?? ""}
              onChange={(e) => {
                setSelectedLocation(locations.find(l => l.id === e.target.value));
                setSelectedChargers([])
              }}>
              {locations.map((single) => (
                <MenuItem
                  dense
                  key={single.id}
                  value={single.id}
                >
                  {single.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={typeof errors.locationError !== 'undefined'}>
              {errors.locationError}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={{ mt: 2, width: '16vw' }}>
            <InputLabel shrink size="small" id="demo-simple-select-label">Charge Points</InputLabel>
            <Select
              displayEmpty
              size="small"
              labelId="select-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={selectedChargers}
              onChange={handleChange}
              startAdornment={selectedLocation ? null :
                <Tooltip title="Select a location first" placement="left">
                  <InputAdornment position="start">
                    <InfoIcon></InfoIcon>
                  </InputAdornment>
                </Tooltip>}
              renderValue={(selected) =>
                  selected.map((obj) => (ChargePoints.filter(e => e.id == obj).map(x => x.chargePointId))).join(", ")

              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {ChargePoints.filter(x => x.location?.id === selectedLocation?.id).map((single) => (
                <MenuItem
                  key={single.id}
                  value={single.id}
                >
                  <Checkbox checked={selectedChargers.indexOf(single.id) > -1} />
                      <ListItemText primary={single.chargePointId} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl error={typeof errors.typeError !== 'undefined' && typeof maxLoad == 'undefined'} variant="standard" sx={{ mt: 2, width: '12vw' }}>
            <InputLabel required shrink >Max Load (Ampere)</InputLabel>
            <Input
              type="number"
              size="small"
              onChange={(e) => setMaxLoad(e.target.value)}
              placeholder={"Enter max load"}
              value={maxLoad}
              sx={{ mt: 2, width: '6vw' }}
              endAdornment={<InputAdornment position="end">A</InputAdornment>}
            />
            <FormHelperText error={typeof errors.typeError !== 'undefined'}>
              {errors.typeError}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ gap: 1, mt: 6, mb: 9 }}
          flexWrap="nowrap"
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{
              textTransform:'none',
              borderRadius: 0,
              padding: "0px 8px",
              whiteSpace: "nowrap",
              minWidth: "max-content",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
}