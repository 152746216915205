import * as React from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Checkbox, FormControl, InputAdornment, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { fetchLoadGroups } from '../../../services/LoadGroupService';
import { fetchLocations } from '../../../services/LocationService';
import { fetchServiceProviders } from '../../../services/ServiceProviderService';
import { UpdateProps } from '../../../react-app-env';
import { fetchChargePoint, patchChargePoint } from '../../../services/ChargePointService';

export default function ChargePointUpdate({ closeSideSheet, setRefresh, entity, refresh }: UpdateProps) {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [Locations, setLocations] = useState<any[]>([])
    const [LoadGroups, setLoadGroups] = useState<any[]>([])
    const [ServiceProviders, setServiceProviders] = useState<any[]>([])
    const [selected, setSelected] = useState<any>();
    const [OG, setOG] = useState<any>();
    let [selectedLocation, setSelectedLocation] = useState<any>();
    let [selectedLoadGroup, setSelectedLoadGroup] = useState<any>();
    let [selectedServiceProviders, setSelectedServiceProviders] = useState<any>();
    const [errors, setErrors] = useState<{ nameError?: string; deleteError?: string; unauthorizedError?: string }>({});

    const handleCancel = () => {
        closeSideSheet();
    }

    const UpdateField = (requestBody: Object[], current: string | number, updated: string | number, path: string) => {
        if (JSON.stringify(current) !== JSON.stringify(updated)) {
            requestBody.push
                ({
                    "op": "replace",
                    "path": path,
                    "value": updated
                });
        }
    }

    const handleClick = async () => {
        const reqBody: Object[] = [];
        if (validateForm()) {
            if (selectedLocation?.id !== OG.location?.id) {
                if (typeof selectedLocation !== 'undefined') {
                    UpdateField(reqBody, OG.location?.id, selectedLocation?.id, '/location');
                } else if (!selectedLocation && OG.location) {
                    reqBody.push({
                        "op": "remove",
                        "path": "/location"
                    })
                }
            }
            if (selectedLoadGroup?.id !== OG.loadGroup?.id) {
                if (typeof selectedLoadGroup !== 'undefined') {
                    UpdateField(reqBody, OG.loadGroup?.id, selectedLoadGroup?.id, '/loadgroup');
                } else if (!selectedLoadGroup && OG.loadGroup) {
                    reqBody.push({
                        "op": "remove",
                        "path": "/loadgroup"
                    })
                }
            }
            UpdateField(reqBody, OG.chargePointId, selected.chargePointId, '/chargePointId');
            UpdateField(reqBody, OG.displayName, selected.displayName, '/displayName');
            UpdateField(reqBody, OG.description, selected.description, '/description');
            UpdateField(reqBody, OG.username, selected.username, '/username');
            UpdateField(reqBody, OG.password, selected.password, '/password');
            UpdateField(reqBody, OG.clientCertThumb, selected.clientCertThumb, '/clientCertThumb');
            UpdateField(reqBody, OG.serviceProviders?.map((a: { id: any; }) => a.id), selectedServiceProviders, '/serviceproviders');
            const response = await patchChargePoint(entity, reqBody)
            if (response?.ok) {
                setRefresh(!refresh);
                closeSideSheet();
            } else {
                if (response?.status === 401 || response?.status === 403) {
                    errors.unauthorizedError = "Du har ikke rettighed til at rette i tags på en entity";
                }
                else {
                    errors.unauthorizedError = undefined;
                    if (response?.status !== 200) {
                        errors.nameError = "Der skete en fejl ved opdatering af tags med fejl kode: '";
                    }
                }
            }
        }
    }
    useEffect(() => {
        const getLocations = async () => {
            const locationsfromserver = await fetchLocations()
            setLocations(locationsfromserver)
        }
        const getLoadGroups = async () => {
            const loadgroups = await fetchLoadGroups()
            setLoadGroups(loadgroups)
        }
        const getServiceProviders = async () => {
            const serviceprov = await fetchServiceProviders()
            setServiceProviders(serviceprov)
        }
        getServiceProviders()
        getLocations()
        getLoadGroups()
    }, []);

    const handleServiceProviderChange = (event: SelectChangeEvent<typeof selectedServiceProviders>) => {
        const {
            target: { value }
        } = event;
        setSelectedServiceProviders(
            typeof value === "string" ? value.split(",") : value,
        );
    };

    useEffect(() => {
        const getSelected = async () => {
            const Selected = await fetchChargePoint(entity)
            setSelected(Selected)
            setSelectedLocation(Selected?.location ?? "")
            setSelectedLoadGroup(Selected?.loadGroup ?? "")
            setSelectedServiceProviders(Selected?.serviceProviders?.map((a: { id: any; }) => a.id) ?? [""])
            setOG(Selected)
            setLoading(false)
        }
        getSelected()
    }, [entity]);

    const validateForm = () => {
        let e = { ...errors };
        if (selected?.chargePointId?.length < 3) {
            e.nameError = "Please enter an identifier";
            setErrors(e);
            return false;
        } else {
            e.nameError = undefined;
            setErrors(e);
            return true;
        }
    }

    if (isLoading) {
        return <Typography
            sx={{
                mt: 2,
                color: "#030F4F",
                fontSize: 25
            }}
        >
            Loading...
        </Typography>
    }
    return (
        <>
            <Box flex={1} overflow={'auto'} >
                <Typography
                    sx={{
                        mt: 2,
                        color: "#030F4F",
                        fontSize: 25
                    }}
                >
                    Edit Charger
                </Typography>
                <Typography
                    sx={{
                        mt: 1,
                        color: "#030F4F",
                    }}
                >
                    Guid: {entity}
                </Typography>

                <Box flexDirection="column">
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        error={typeof errors.nameError !== 'undefined'}
                        variant="standard"
                        size="small"
                        onChange={(e) => setSelected((selected: any) =>
                        ({
                            ...selected, "chargePointId": e.target.value
                        })
                        )}
                        value={selected?.chargePointId ?? ""}
                        label="Identifier"
                        placeholder={"Enter identifier here"}
                        sx={{ mt: 1, width: '16vw' }}
                        helperText={errors.nameError}
                    />

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        size="small"
                        onChange={(e) => setSelected((selected: any) =>
                        ({
                            ...selected, "displayName": e.target.value
                        })
                        )}
                        value={selected?.displayName ?? ""}
                        label="Display Name"
                        placeholder={"Enter name here"}
                        sx={{ mt: 1, width: '16vw' }}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        label="Description"
                        multiline
                        rows={2}
                        sx={{ mt: 1, width: '16vw' }}
                        value={selected?.description ?? ""}
                        onChange={(e) => {
                            setSelected((selected: any) => ({
                                ...selected, "description": e.target.value
                            }))
                        }}
                    />
                    <FormControl variant="standard" sx={{ mt: 2, width: '16vw' }} size="small">
                        <InputLabel shrink id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            displayEmpty
                            label="Location"
                            value={selectedLocation?.id ?? ""}
                            onChange={(e) => {
                                setSelectedLocation(Locations.find(l => l.id === e.target.value));
                                setSelectedLoadGroup("")
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>

                            {Locations.map((single) => (
                                <MenuItem
                                    key={single.id}
                                    value={single.id}
                                >
                                    {single.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ mt: 2, width: '16vw' }}
                        id="standard-select"
                        select
                        label="Load Group"
                        value={selectedLoadGroup?.id ?? ""}
                        onChange={(e) => {
                            setSelectedLoadGroup(LoadGroups.find(l => l.id === e.target.value))
                        }}
                        variant="standard"
                        InputProps={{
                            startAdornment: selectedLocation ? null :
                                <Tooltip title="Select a location first" placement="left">
                                    <InputAdornment position="start">
                                        <InfoIcon></InfoIcon>
                                    </InputAdornment>
                                </Tooltip>
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {LoadGroups.filter(x => x.location.id === selectedLocation?.id).map((single) => (
                            <MenuItem
                                key={single.id}
                                value={single.id}
                            >
                                {single.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        variant="standard"
                        value={selected?.username ?? ""}
                        onChange={(e) => {
                            setSelected((selected: any) => ({
                                ...selected, "username": e.target.value
                            }))
                        }}
                        size="small"
                        label="Username"
                        placeholder={"Enter username"}
                        sx={{ mt: 1, width: '16vw' }}
                    />
                    <TextField
                        variant="standard"
                        value={selected?.password ?? ""}
                        onChange={(e) => {
                            setSelected((selected: any) => ({
                                ...selected, "password": e.target.value
                            }))
                        }}
                        size="small"
                        label="Password"
                        placeholder={"Enter password"}
                        sx={{ mt: 1, width: '16vw' }}
                    />
                    <TextField
                        variant="standard"
                        value={selected?.clientCertThumb ?? ""}
                        onChange={(e) => {
                            setSelected((selected: any) => ({
                                ...selected, "clientCertThumb": e.target.value
                            }))
                        }}
                        size="small"
                        label="Client Cert Thumb"
                        placeholder={"Enter your client certification thumb"}
                        sx={{ mt: 1, width: '16vw' }}
                    />
                    <FormControl variant="standard" sx={{ mt: 1, width: '16vw' }} >
                        <InputLabel shrink size="small" >Service Providers</InputLabel>
                        <Select
                            multiple
                            displayEmpty
                            size="small"
                            label="Load Group"
                            value={selectedServiceProviders}
                            onChange={handleServiceProviderChange}
                            renderValue={(selected) =>
                                selected.map((obj: any) => (ServiceProviders.filter(e => e.id === obj).map(x => x.name))).join(", ")
                            }
                            defaultValue={selectedServiceProviders} >
                            {ServiceProviders.map((single) => (
                                <MenuItem key={single.id} value={single.id}>
                                    <Checkbox checked={selectedServiceProviders.indexOf(single.id) > -1} />
                                    <ListItemText primary={single.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        variant="standard"
                        value={selected?.model ?? ""}
                        disabled
                        size="small"
                        label="Model"
                        sx={{
                            mt: 1, width: '16vw', "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                            },
                        }}
                    />
                    <TextField
                        variant="standard"
                        value={selected?.vendor ?? ""}
                        disabled
                        size="small"
                        label="Vendor"
                        sx={{
                            mt: 1, width: '16vw', "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                            },
                        }}
                    />
                    <TextField
                        variant="standard"
                        value={selected?.serialNo ?? ""}
                        disabled
                        size="small"
                        label="Serial No"
                        sx={{
                            mt: 1, width: '16vw', "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                            },
                        }}
                    />
                    <TextField
                        variant="standard"
                        value={selected?.firmwareVersion ?? ""}
                        disabled
                        size="small"
                        label="Firmware Version"
                        sx={{
                            mt: 1, width: '16vw', "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                            },
                        }}
                    />
                    <TextField
                        variant="standard"
                        value={selected?.type ?? ""}
                        disabled
                        size="small"
                        label="Type"
                        sx={{
                            mt: 1, width: '16vw', "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                            },
                        }}
                    />

                </Box>
            </Box>
            <Box>

                <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ gap: 1, mt: 6, mb: 3 }}
                    flexWrap="nowrap"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 0,
                            padding: "0px 8px",
                            whiteSpace: "nowrap",
                            minWidth: "max-content",
                        }}
                    >
                        Update
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 0,
                            padding: "0px 8px",
                            whiteSpace: "nowrap",
                            minWidth: "max-content",
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </>
    )
}