import { Box, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Drawer } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ActionBarButton from '../../components/ActionBarButton';
import AddIcon from '@mui/icons-material/Add';
import { Add, Edit, Refresh } from '@mui/icons-material';
import CustomDivider from '../../components/CustomDivider';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { fetchTransactions } from '../../services/TransactionService';
import { format, parseISO } from 'date-fns';
import { ActionBar, ActionButton } from 'prolonreactcomponents';
import GridLocalSearchField from '../../components/GridLocalSearchField';

export default function TransactionsListPage() {
    const [errors, setErrors] = useState(
        {
            hasFetchError: false,
            fetchError: null,
        }
    );
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            type: 'string',
            width: 150
        }
    ];
    const [transactions, setTransactions] = useState([]);

    const getTransactions = async () => {
        const transactionsfromserver = await fetchTransactions()
        setTransactions(transactionsfromserver)
    }

    useEffect(() => {
        getTransactions()
    }, [])

    const ToolBar = () => {
        return (
            <Box>
                <ActionBar paddingBottom={0} marginLeft={5}>
                    <ActionButton
                        text="Refresh"
                        action={() => {
                            getTransactions();
                        }}
                        icon={<Refresh />}
                    />

                    <GridLocalSearchField />
                </ActionBar>
            </Box>
        )
    }

    return (
        <Box display='flex' flex={1} sx={{ height: "100%" }}>
            <Box display='flex' flex={1} flexDirection="column">
                <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
                    <Box style={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                        <Typography variant='h6' sx={{
                            pl: 2,
                            py: 1
                        }} >
                            Transactions
                        </Typography>
                        <DataGridPro
                            rows={transactions}
                            columns={columns}
                            density='compact'
                            autoPageSize
                            pagination
                            rowHeight={30}
                            components={{
                                Toolbar: ToolBar
                            }}
                            sx={{
                                border: 0,
                                "& .MuiDataGrid-pinnedColumnHeaders": {
                                    boxShadow: "none",
                                    backgroundColor: "transparent"
                                },
                                "& .MuiDataGrid-pinnedColumns": {
                                    boxShadow: "none",
                                    // backgroundColor: "transparent",
                                    "& .MuiDataGrid-cell": {
                                        padding: 0
                                    }
                                },
                                "& .MuiDataGrid-row": {
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "whitesmoke"
                                    },
                                    "&:first-of-type": {
                                        borderTop: "1px solid rgba(224, 224, 224, 1)"
                                    }
                                },
                                "& .MuiDataGrid-cell:focus": {
                                    outline: "none"
                                },
                                "& .MuiDataGrid-cell:focus-within": {
                                    outline: "none"
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}