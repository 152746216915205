import React from 'react'
import { Box } from '@mui/material';
import { useState } from 'react';
import LocationListView from './LocationListView';

export default function LocationListPage() {
  const [sideSheet, setSideSheet] = useState<React.ReactNode |null>(null);

  return (
    <Box display='flex' flex={1} sx={{ height: "100%" }}>
      <Box display='flex' flex={1} flexDirection="column">
        <LocationListView setSideSheet={setSideSheet}/>
      </Box>
      {sideSheet}
    </Box>
  )
}