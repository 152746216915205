import { baseAddress } from "../Config";

export async function fetchLocations() {
  try {
    const resp = await fetch(baseAddress + '/Locations', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    const data = await resp.json()
    // console.log(JSON.stringify(data))
    return data
  }
  catch (e) {
    console.error(e);
  }
}

export async function fetchLocation(id: string) {
  try {
    const resp = await fetch(baseAddress + '/locations/' + id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    const data = await resp.json()
    // console.log(JSON.stringify(data))
    return data
  }
  catch (e) {
    console.error(e);
  }
}

export async function deleteLocation(id: string) {
  try {
    const resp = await fetch(baseAddress + `/locations/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return resp.ok
  }
  catch (e) {
    console.error(e);
  }
}

export async function postLocation(reqBody: Object) {
  try {
    const resp = await fetch(baseAddress + '/locations/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    });
    return resp
  }
  catch (e) {
    console.error(e);
  }
}

export async function patchLocation(id: string, reqBody: Object) {
  try {
    const resp = await fetch(baseAddress + `/locations/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    });
    return resp
  }
  catch (e) {
    console.error(e);
  }
}
